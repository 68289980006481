import axios, { AxiosResponse } from 'axios';

const getProduct = async (key, id: number, cb1 = (data) => {}, cb2 = (err) => {}) => {
  const url: string | undefined = process.env.REACT_APP_URL;
  const fullUrl: string = `${url}/products/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${key}`,
    },
  };

  try {
    const res: AxiosResponse = await axios.get(fullUrl, config);
    cb1(res.data);
  } catch (err) {
    cb2(err);
  }
};

export default getProduct;
