import { User, Address } from "./../api/postUser";
import { getCountries, CountryObject } from "../api/countries";
import { useState } from "react";
import { useCookies } from "react-cookie";
import getCountryById from "../api/getCountryById";
import _ from "lodash";

export interface Properties {
  vals: {
    email: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    countryId: number;
    region: number;
    country: string;
  };
  dataToPost: {
    email: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    password: string;
    confirmPassword: string;
    city: string;
    region: number;
    country: number;
    activationNotes: string;
  };
  dataToPut: {
    email: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    region: number;
    country: number;
  };
  retrieve: (data: any) => void;
  tools: {
    resetAll: () => void;
  };
  set: {
    setCountry: (country: CountryObject) => void;
  };
  error: {
    checkForErrors: () => boolean;
    hasError: (name: string) => boolean;
    add: (val: string) => void;
    remove: (val: string) => void;
  };
  countryList: CountryObject[];
}

const checkPostalRegion = (val, regions) => {
  let matchedRegion = null;
  let initialCode = val.split("-")[0];
  regions.forEach((region) => {
    let postal = {
      from: Number(region.postalCodeFrom),
      until: Number(region.postalCodeUntil),
    };
    if (!postal.from && !postal.until) {
      matchedRegion = region.id;
    }
    for (let i = postal.from; i <= postal.until; i++) {
      if (i === Number(initialCode)) {
        matchedRegion = region.id;
      }
    }
  });
  return matchedRegion;
};

const checkForCountryList = (value, countryList: CountryObject[]) => {
  if (countryList) {
    let foundCountry = null;
    let valueUpper = String(value).toUpperCase();
    countryList.forEach((item) => {
      let itemNameUpp = item.name.toUpperCase();
      if (itemNameUpp === valueUpper) {
        foundCountry = item;
      }
    });
    return foundCountry;
  }
};

interface Settings {
  noPassword: boolean;
}

const defaultSettings = {
  noPassword: false,
};

export default function useRegister(settings = defaultSettings) {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState<string>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [vatNumber, setVatNumber] = useState<string>("");
  const [mobilePrivate, setMobilePrivate] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  //No input but data
  const [countryId, setCountryId] = useState<number | null>(null);
  const [region, setRegion] = useState<number>(null);
  //Input with no data
  const [country, setCountry] = useState<string>("");
  //Management
  const [errors, setErrors] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<CountryObject | null>(null);
  const [countryList, setCountryList] = useState<CountryObject[]>(null);
  const [cookie] = useCookies();

  //Errors
  const removeError = (name: string) => {
    const newArray = errors.filter((errorName) => {
      return errorName !== name;
    });
    setErrors(newArray);
  };

  const hasError = (name: string) => {
    const found = errors.includes(name);
    return found;
  };

  const checkPassword = (val: string) => {
    if (val === password) {
      return true;
    } else return false;
  };

  const checkForErrors = () => {
    let errorFields = [
      { name: "name", value: name },
      { name: "email", value: email },
      { name: "country", value: country },
      { name: "confirmPassword", value: confirmPassword },
      { name: "postalCode", value: postalCode },
      { name: "addressLine1", value: addressLine1 },
      { name: "mobilePrivate", value: mobilePrivate },
      { name: "vatNumber", value: vatNumber },
    ];

    if (settings.noPassword) {
      delete errorFields[3];
    }

    let emptyFields: string[] = [];
    errorFields.forEach((input) => {
      if (input.value === "" && input.name !== "country") {
        emptyFields.push(input.name);
      } else if (input.name === "country") {
        if (!countryId) {
          emptyFields.push(input.name);
        }
      }
    });

    if (emptyFields.length === 0) {
      const isPasswordValid = checkPassword(confirmPassword);
      setErrors(isPasswordValid ? [] : ["confirmPassword"]);
      checkPostalCodeFormat(postalCode, selectedCountry);
      return false;
    } else {
      setErrors([...errors, ...emptyFields]);
      return true;
    }
  };

  const checkPostalCodeFormat = (currentPostal: string, country: CountryObject) => {
    const format = country.postalCodeFormat;
    if (format && country) {
      const regex = new RegExp(unescape(format));
      const isValid = regex.test(currentPostal);
      if (!isValid) {
        setErrors([...errors, "postalCode"]);
      } else {
        removeError("postalCode");
      }
    } else {
      removeError("postalCode");
    }
  };

  const removeCountry = () => {
    setCountryId(null);
    setCountry("");
    setSelectedCountry(null);
    setRegion(null);
  };

  const addCountry = (country: CountryObject) => {
    setCountryId(country.id);
    setCountry(country.name);
    setSelectedCountry(country);
    setCountryList(null);
    checkPostalCodeFormat(postalCode, country);
    setRegion(checkPostalRegion(postalCode, country.regions));
  };

  //Special Input Management
  const handleCountry = (val: string) => {
    removeError("country");
    removeCountry();
    if (val.length > 1) {
      getCountries(cookie["alflora-key"], val, (data) => {
        setCountryList(data);
        let countryFound: CountryObject = checkForCountryList(val, data);
        if (countryFound) {
          addCountry(countryFound);
        }
      });
    }
    setCountry(val);
  };

  const handlePostalCode = (val: string) => {
    if (selectedCountry) {
      checkPostalCodeFormat(val, selectedCountry);
      setRegion(checkPostalRegion(val, selectedCountry.regions));
    }
    setPostalCode(val);
  };

  const handleConfirmPassword = (val: string) => {
    if (val.length > 0 && password.length > 0) {
      const matches = checkPassword(val);
      if (!matches) {
        setErrors([...errors, "confirmPassword"]);
      } else {
        removeError("confirmPassword");
      }
    } else {
      removeError("confirmPassword");
    }
    setConfirmPassword(val);
  };

  const retrieve = (user: User) => {
    const activationNotes = JSON.parse(user.activationNotes);
    setName(user.name);
    setEmail(user.email);

    if (_.has(activationNotes, "Telemóvel")) setMobilePrivate(activationNotes.Telemóvel);
    if (_.has(activationNotes, "NIF")) setVatNumber(activationNotes.NIF);

    if (user.entity && user.entity.billingAddress && typeof user.entity.billingAddress === "object") {
      const address: Address = user.entity.billingAddress;
      const regionId = typeof address.region === "number" ? address.region : address.region ? address.region.id : null;
      setAddressLine1(address.addressLine1);
      setAddressLine2(address.addressLine2);
      setPostalCode(address.postalCode);
      setCity(address.city);
      setRegion(regionId);
      if (typeof address.country === "number") {
        getCountryById(cookie["alflora-key"], address.country, (country: CountryObject) => {
          setCountryId(country.id);
          setCountry(country.name);
          setSelectedCountry(country);
        });
      } else if (address.country) {
        setCountryId(address.country.id);
        setCountry(address.country.name);
        setSelectedCountry(address.country);
      }
    }
  };

  const resetAll = () => {
    setEmail("");
    setName("");
    setAddressLine1("");
    setAddressLine2("");
    setPostalCode("");
    setCity("");
    setPassword("");
    setConfirmPassword("");
    setCountryId(null);
    setRegion(null);
    setCountry("");
    setMobilePrivate("");
    setVatNumber("");
    setErrors([]);
    setSelectedCountry(null);
    setCountryList(null);
  };

  const bind = {
    email: {
      value: email,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        removeError("email");
        setEmail(e.target.value);
      },
    },
    name: {
      value: name,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        removeError("name");
        setName(e.target.value);
      },
    },
    addressLine1: {
      value: addressLine1,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        removeError("addressLine1");
        setAddressLine1(e.target.value);
      },
    },
    addressLine2: {
      value: addressLine2,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddressLine2(e.target.value);
      },
    },
    postalCode: {
      value: postalCode,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handlePostalCode(e.target.value);
      },
    },
    city: {
      value: city,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCity(e.target.value);
      },
    },
    country: {
      value: country,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handleCountry(e.target.value);
      },
    },
    vatNumber: {
      value: vatNumber,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        removeError("vatNumber");
        setVatNumber(e.target.value);
      },
    },
    mobilePrivate: {
      value: mobilePrivate,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        removeError("mobilePrivate");
        setMobilePrivate(e.target.value);
      },
    },
    password: {
      value: password,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
      },
    },
    confirmPassword: {
      value: confirmPassword,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handleConfirmPassword(e.target.value);
      },
    },
  };

  const properties: Properties = {
    vals: {
      email,
      name,
      addressLine1,
      addressLine2,
      postalCode,
      city,
      countryId,
      region,
      country,
    },
    retrieve,
    dataToPost: {
      email,
      name,
      addressLine1,
      activationNotes: JSON.stringify({ NIF: vatNumber, Telemóvel: mobilePrivate }),
      addressLine2,
      postalCode,
      city,
      confirmPassword,
      password,
      region,
      country: countryId,
    },
    dataToPut: {
      email,
      name,
      addressLine1,
      addressLine2,
      postalCode,
      city,
      region,
      country: countryId,
    },
    tools: {
      resetAll,
    },
    set: {
      setCountry: addCountry,
    },
    error: {
      checkForErrors,
      hasError,
      add: (val: string) => setErrors([...errors, val]),
      remove: removeError,
    },
    countryList,
  };

  return { bind, properties };
}
