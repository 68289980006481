import React from "react";
import CartCard from "./CartCard";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Product } from "../../../pages/NewProducts/types";
import { CartWrapper, CartTitle, QuantifySpan, CartButton } from "./styled";

const SubTotal = styled.p`
  font-size: 15px;
  font-family: arial;
  font-weight: 800;
  color: #333;
  margin: 10px 0;
`;

const MinimumWarning = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: #040404;
  margin-top: 0;
`;

type CartPropTypes = {
  open: boolean;
  cart: Product[];
  options: any;
  mobile?: boolean;
};

const Cart: React.FC<CartPropTypes> = ({ open, cart, options, mobile = false }) => {
  const history = useHistory();

  const order = () => {
    options.closeCart();
    history.push("/checkout");
  };

  return open && options.totalItems !== 0 ? (
    <CartWrapper mobile={mobile}>
      <CartTitle>
        Tem <QuantifySpan>{options.totalItems === 1 ? "1 item" : `${options.totalItems} items`}</QuantifySpan> no seu carrinho
      </CartTitle>
      {cart ? cart.map((prod: Product, idx: number) => <CartCard product={prod} del={options.del} key={idx} />) : null}
      <SubTotal>Subtotal: {options.total.toFixed(2)}€</SubTotal>
      <MinimumWarning>Minimo de 100€ requirido para efetuar encomenda</MinimumWarning>
      <CartButton onClick={order}>Encomendar</CartButton>
    </CartWrapper>
  ) : null;
};

export default React.memo(Cart);
