import React from 'react';
import { Link } from 'react-router-dom';
import { ContentWrapper, TextContainer, H4, PostCardWrapper } from './styled';

const dateStyle = {
  fontSize: '17px',
  fontStyle: 'italic',
};

type PostCardProps = {
  post: {
    heading: string | undefined;
    body: string | undefined;
    groupName: string | undefined;
    publishFrom: string | undefined;
    publishUntil: string | undefined;
    id: number;
    createdAt: string | undefined;
    updatedAt: string | undefined;
  };
  css?: string;
};

const PostCard: React.FC<PostCardProps> = ({ post, css = '' }) => {
  const date = new Date(post.createdAt);
  const datePretty: string = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;

  return (
    <PostCardWrapper css={css}>
      <Link to={`/noticias-detalhe/${post.id}`}>
        <ContentWrapper>
          <H4>{post.heading}</H4>
          <div className="p-4 bg-gray-300">
            <TextContainer>
              <div dangerouslySetInnerHTML={{ __html: post.body ? post.body : '' }}></div>
            </TextContainer>
          </div>
          <div className="w-full text-right my-2">
            <span className="ml-auto mt-4 text-right w-full text-md" style={dateStyle}>
              {datePretty}
            </span>
          </div>
        </ContentWrapper>
      </Link>
    </PostCardWrapper>
  );
};

export default React.memo(PostCard);
