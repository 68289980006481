import styled from 'styled-components';

const ProductsFullWrapper = styled.div`
  padding: 2.5rem 6rem;

  @media (max-width: 1200px) {
    padding: 2.5rem 1rem;
  }
  @media (max-width: 630px) {
    padding: 2.5rem 0.25rem;
    padding: 0.35rem 0;
  }
`;

const ProductWrapper = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const FilterWrapper = styled.div`
  width: 25%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const UpperWrapper = styled.div`
  margin: 0 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 1024px) {
    margin: 0 0.5rem;
  }
  @media (max-width: 425px) {
    margin: 0 0.15rem;
  }
`;
const UpperPaginationWrapper = styled.div`
  margin-left: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const NoProducts = styled.p`
  font-size: 18px;
  text-align: center;
  width: 100%;
  margin-top: 70px;
`;
const StockWarning = styled.h3`
  margin-top: 0;
  @media (max-width: 630px) {
    margin: 10px 7px;
    text-transform: uppercase;
    font-size: 15px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const ProductAndFilterWrapper = styled.div`
  margin: 0 1.5rem;
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    margin: 0 0.5rem;
  }
`;

export {
  ProductsFullWrapper,
  NoProducts,
  ProductWrapper,
  FilterWrapper,
  UpperWrapper,
  UpperPaginationWrapper,
  StockWarning,
  ProductAndFilterWrapper,
};
