import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import _ from 'lodash';

type GetState = (propertyName?: string) => any;

export interface PreviousStateTools {
  getState: GetState;
}

let prevLocationArray: string[] = [];

export default function usePreviousState(): [PreviousStateTools] {
  const { pathname } = useLocation();

  //window.onpopstate = (e) => {
  //  console.log('BACK BUTTON?');
  //};

  const getLastLocation = () => {
    let prevLocationidx: number = prevLocationArray.length - 1 >= 0 ? prevLocationArray.length - 1 : 0;
    let prevLocation: string = prevLocationArray[prevLocationidx];

    return prevLocation;
  };

  const getState: GetState = (propertyName: string = 'paramsToUse') => {
    const historyState = window.history.state;
    const hasProperty = _.has(historyState, propertyName);

    let prevLocation = getLastLocation();

    if (hasProperty && prevLocation) {
      const stateToReturn: any = historyState[propertyName];

      if (prevLocation && prevLocation !== pathname) {
        return stateToReturn;
      } else return null;
    } else return null;
  };

  const tools = {
    getState,
    prevLocations: prevLocationArray,
  };

  useEffect(() => {
    prevLocationArray.push(pathname);
  });

  return [tools];
}
