import styled from 'styled-components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const FontAwesomeIcon = styled(Icon)`
  color: ${process.env.REACT_APP_2COLOR};
`;
const Title = styled.h2`
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 20px;
  margin-bottom: 2rem;
`;

const TextArea = styled.textarea`
  border: 1px solid #333;
  padding: 10px 10px;
  width: 100%;
  display: block;
  min-height: 50px;
  background-color: #fafafa;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #333;
  }
`;

export { FontAwesomeIcon, Title, TextArea };
