import axios, { AxiosResponse } from "axios";

export interface CountryObject {
  language: number;
  shippingZone: string | null;
  name: string | null;
  abbreviation: string | null;
  unCode: string | null;
  phoneCode: string | null;
  taxName: string | null;
  useRegions: boolean;
  postalCodeFormat: string | null;
  id: number;
  createdAt: string | null;
  updatedAt: string | null;
  regions: {
    country: number;
    name: string;
    abbreviation: string | null;
    unCode: string | null;
    postalCodeFrom: null | string;
    postalCodeUntil: null | string;
    shippingZone: number;
    id: number;
    createdAt: null | string;
    updatedAt: null | string;
  }[];
}

export const getCountries = async (key: string, input: string, onSuccess) => {
  const url = `${process.env.REACT_APP_URL}/countries`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${key}`,
    },
    params: {
      limit: 5,
      populate: "regions",
      where: {
        name: { contains: input },
      },
    },
  };

  try {
    const res: AxiosResponse = await axios.get(url, config);
    onSuccess(res.data);
  } catch (err) {
    console.log(err);
  }
};
