import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HomeMobileTitle = styled.p`
  color: white;
  font-weight: bolder;
  font-size: 70px;
  z-index: 2;
  margin: 0 0 0 auto;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.1s linear;
  @media (max-width: 500px) {
    font-size: 60px;
  }
`;

const HomeMobileWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    padding: 10px 20px;
  }
`;
const ImageBackground = styled.img`
  width: 100vw;
  border-bottom: 1px solid #ccc;
  height: 100vh;
`;

const HomeMobileAnchor = styled(Link)`
  color: white;
  font-weight: bolder;
  font-size: 35px;
  z-index: 2;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 0px;
`;

const HomeMobileButton = styled.button`
  color: white;
  font-weight: bolder;
  font-size: 35px;
  z-index: 2;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  padding: 5px 0px;
`;
const ImageDarkWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #0000009e;
`;
const MobileHomeSection = styled.section`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const TitleGreen = styled.span`
  font: 700 28px/28px 'Lato', sans-serif;
  color: ${process.env.REACT_APP_2COLOR};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0rem;
`;

const HomeSection = styled.section`
  margin: 6rem;
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export {
  HomeMobileTitle,
  HomeMobileWrapper,
  ImageBackground,
  HomeMobileAnchor,
  HomeMobileButton,
  ImageDarkWrapper,
  MobileHomeSection,
  TitleGreen,
  HomeSection,
};
