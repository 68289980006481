import { Product } from './../components/pages/NewProducts/types';
import axios, { AxiosResponse } from 'axios';
import getProducts from './getProducts';

const getProducts2 = async (key: string, actualPage: number, currentSort: string, limit: number, filters) => {
  const url: string | undefined = process.env.REACT_APP_URL;
  const fullUrl: string = `${url}/products/filter`;

  const params = {
    page: actualPage,
    active: String(true),
    limit: limit,
    sku: '%',
    stock: '>0',
    sort: currentSort,
    ...filters,
  };

  let config: any = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${key}`,
    },
    params,
  };

  try {
    const res: AxiosResponse = await axios.get(fullUrl, config);
    return res.data.product;
  } catch (err) {}
};

const getLimitArray = (fullLimit: number) => {
  const limitPerPage: number = Number(process.env.REACT_APP_GET_PRODUCT_LIMIT);
  const limitArray: number[] = [];

  const lastLimit: number = fullLimit % limitPerPage; // DIVIDE BY 50
  const fullPageTimes: number = (fullLimit - lastLimit) / limitPerPage; // DIVIDE BY 50

  for (let i = 0; i < fullPageTimes; i++) {
    limitArray.push(21); // PUSH 50
  }

  if (lastLimit > 0) limitArray.push(lastLimit);

  return limitArray;
};

const getLastProducts = async (key: string, actualPage: number, currentSort: string, filters, cb = (productArray) => {}) => {
  const limitPerPage: number = Number(process.env.REACT_APP_GET_PRODUCT_LIMIT);
  const fullLimit: number = (actualPage - 1) * limitPerPage;
  let products = [];

  if (fullLimit <= limitPerPage) {
    products = await getProducts2(key, 1, currentSort, fullLimit, filters);
  } else {
    const limitArray: number[] = getLimitArray(fullLimit);

    const promiseArray = limitArray.reduce((acc, limit, idx) => {
      const pageToUse: number = idx + 1;

      const newPromise = new Promise(async (resolve) => {
        const products = await getProducts2(key, pageToUse, currentSort, limit, filters);
        resolve(products);
      });

      return acc.concat([newPromise]);
    }, []);

    const allBeforeProducts: Product[][] = await Promise.all(promiseArray);

    products = allBeforeProducts.reduce((acc: Product[], productList: Product[]) => {
      const updatedList: Product[] = acc.concat(productList);
      return updatedList;
    }, []);

  }

  getProducts(key, actualPage, currentSort, filters, (data) => {
    data.product = [...products, ...data.product];
    cb(data);
  });
};

export default getLastProducts;
