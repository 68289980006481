import React from 'react';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30vh;
  margin-bottom: 10vh;
`;

export default function LoadingSpinner() {
  return (
    <LoadingWrapper>
      <div className="lds-ring mx-auto my-auto">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingWrapper>
  );
}
