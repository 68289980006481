import styled from 'styled-components';

const BlackDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000008c;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  cursor: pointer;
  ${({ open }) => !open && `display: hidden`};
`;

const SuccessMessage = styled.p`
  position: relative;
  text-align: center;
  font-size: 20px;
  top: -15px;
  padding: 30px 0;
  margin: 15px;
`;

const ModalTitle = styled.h3`
  background: ${process.env.REACT_APP_2COLOR};
  padding: 25px 15px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: bolder;
  margin-top: 0;
  margin-bottom: 0;
`;

const ContentWrapper = styled.div`
  padding: 10px;
  margin-top: auto;
  margin-bottom: auto;
`;

const Modal = styled.div`
  background-color: white;
  width: 40vw;
  left: calc(50% - 20vw);
  height: fit-content !important;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: calc(50% - 20vh);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  @media (max-width: 1024px) {
    width: 60vw;
    left: calc(50% - 30vw);
  }
  @media (max-width: 768px) {
    width: 70vw;
    left: calc(50% - 35vw);
  }
  @media (max-width: 500px) {
    width: 80vw;
    left: calc(50% - 40vw);
  }
  @media (max-width: 400px) {
    width: 90vw;
    left: calc(50% - 45vw);
  }
  ${({ open }) => !open && `display: hidden`};
`;

export { BlackDiv, ModalTitle, ContentWrapper, Modal, SuccessMessage };
