import { useState } from 'react';

export interface ErrorTools {
  add: (name: string) => void;
  addMany: (errorArray: string[]) => void;
  remove: (name: string) => void;
  reset: () => void;
  hasError: (name: string) => boolean;
}

const useErrors = () => {
  const [errors, setErrors] = useState<string[]>([]);

  //ADDING
  const add = (name: string) => setErrors([...errors, name]);

  const addMany = (errorArray: string[]) => setErrors([...errors, ...errorArray]);

  const addTimeout = (name: string, time?: number, cb = () => {}) => {
    add(name);
    setTimeout(
      () => {
        remove(name);
        cb();
      },
      time ? time : 2500
    );
  };

  //REMOVING
  const reset = () => setErrors([]);

  const remove = (name: string | string[]) => {
    const errorCopy: string[] = [...errors];
    const newErrors = errorCopy.filter((errorName: string) => {
      const bool: boolean = typeof name === 'string' ? errorName !== name : !name.includes(errorName);
      return bool;
    });

    setErrors(newErrors);
  };

  //CHECKING
  const hasError = (name: string) => errors.includes(name);
  const hasErrors = (nameArray: string[]) => {
    let value: boolean = false;
    nameArray.forEach((name: string) => {
      const bool: boolean = errors.includes(name);
      if (bool) value = true;
    });

    return value;
  };

  const errorTools = {
    add,
    addMany,
    addTimeout,
    remove,
    reset,
    hasError,
    hasErrors,
  };

  return { errors, errorTools };
};

export default useErrors;
