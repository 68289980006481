import styled from 'styled-components';

export const SkuSpan = styled.span`
  color: #868686;
  font-size: 14px;
  font-weight: 600;
`;

export const NameSpan = styled.span`
  display: block;
  font-weight: 600;
  font-family: arial;
`;

export const Image = styled.img`
  width: 95px;
  height: 90px;
  object-fit: contain;
  margin: 6px auto 0 0;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const TD = styled.td`
  min-height: 96px;
  @media(max-width: 525px) ( 
    font-size: 14px;
  )
  ${({ css }) => css && css}
`;

export const QuantitySpan = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: inline-block;
  }
`;
export const QuantitySpan2 = styled.span`
  display: inline-block;
  @media (max-width: 768px) {
    color: white;
  }
`;

export const TextArea = styled.textarea`
background: white;
margin-top: 20px;
    margin-bottom: 20px;
padding: 10px 15px;
height: 100%;
color: #333;
border: 1px solid #ccc;
min-height: 100px;
max-height: 240px;
resize: none;
  &:focus  {
    outline: none;
  }
  &::placeholder { 
    color: #616161;
  }

  @media(max-width: 1024px) { 
    min-height: 150px;
    max-height: 190px;
  }
`;

export const CheckoutH = styled.h2`
  font-size: 33px;
  margin-bottom: 20px;
  color: #333;
  margin-top: 5px;
  @media (max-width: 525px) {
    display: none;
  }
`;

export const TH = styled.th`
  color: #333;
  font-size: 18px;
  padding-bottom: 10px;
  ${({ css }) => css && css}
`;

export const TR = styled.tr`
  border-bottom: 1px solid #ccc;
`;

export const PaymentP = styled.p`
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 7px;
  font-family: arial;
  display: flex;
  flex-direction: row;
  color: #333;
  ${({ css }) => css && css}
`;

export const PaymentWrapper = styled.div`
  border-radius: 5px;
  background: #f1f1f1;
  padding: 0 20px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 460px;
`;
