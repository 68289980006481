import axios, { AxiosResponse } from "axios";

const getOrders = async (key, actualPage: number | any, onSuccess = (res) => {}) => {
  const url: string | undefined = process.env.REACT_APP_URL;
  const fullUrl: string = `${url}/orders`;

  const config = {
    params: {
      page: actualPage,
      limit: 9
    },
    headers: {
      Authorization: `Bearer ${key}`
    }
  };

  try {
    const res: AxiosResponse = await axios.get(fullUrl, config);
    onSuccess(res.data);
  } catch (err) {
    console.log(err);
  }
};

export default getOrders;
