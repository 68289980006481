import styled from 'styled-components';

const TextContainer = styled.div`
  height: 100px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 1rem;
  font-size: 14px;
`;
const PostCardWrapper = styled.div`
  width: 33.3%;
  padding: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    padding: 0.5rem 1rem;
  }
  ${({ css }) => css && `${css}`}
`;
const H4 = styled.h4`
  height: 50px;
  text-align: center;
  color: ${process.env.REACT_APP_2COLOR};
  font-weight: 600;
  font-size: 16px;
`;

const ContentWrapper = styled.div`
  padding: 0.5rem;
  position: relative;
  border: 1px solid #ccc;
  transition: 0.3s ease;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  &:hover {
    border-color: ${process.env.REACT_APP_2COLOR};
  }
  @media (max-width: 768px) {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
`;

export { TextContainer, H4, ContentWrapper, PostCardWrapper };
