import React, { useState, useEffect } from "react";
import { Product } from "../components/pages/NewProducts/types";
import { useCookies } from "react-cookie";
import _ from "lodash";
import { User } from "../api/postUser";
import useModal from "../hooks/useModal";

export const CartContext = React.createContext([]);

const checkForProduct = (id: number, cart: Product[]) => {
  let found: boolean = false;
  cart.forEach((cartProd: Product) => {
    const cartProdId: number = Number(cartProd.id);
    if (cartProdId === Number(id)) {
      found = true;
    }
  });

  return found;
};

const saveCart = (cart: Product[], user: User) => {
  let cartsSaved: any = localStorage.getItem("alflora-cart");
  if (cartsSaved) cartsSaved = JSON.parse(cartsSaved);
  if (!cartsSaved) cartsSaved = {};
  const cartsToSave = Object.assign(cartsSaved, { [user.id]: cart });
  localStorage.setItem("alflora-cart", JSON.stringify(cartsToSave));
};

const retrieveCart = (user: User) => {
  let cartsSaved: any = localStorage.getItem("alflora-cart");
  if (cartsSaved) cartsSaved = JSON.parse(cartsSaved);
  if (!cartsSaved) cartsSaved = {};
  if (!user) return [];
  const cartToRetrieve: Product[] = _.has(cartsSaved, user.id) ? cartsSaved[user.id] : [];
  return cartToRetrieve;
};

const removeCart = (user: User) => {
  let cartsSaved: any = localStorage.getItem("alflora-cart");
  let hasUserCart: boolean = false;
  if (cartsSaved && user) {
    cartsSaved = JSON.parse(cartsSaved);
    hasUserCart = _.has(cartsSaved, user.id);
    if (hasUserCart) delete cartsSaved[user.id];
    localStorage.setItem("alflora-cart", JSON.stringify(cartsSaved));
  }
};

const getTotalCart = (cart: Product[]) => {
  const total = cart.reduce((acc, cartProd: Product) => Number(cartProd.total) + acc, 0);
  return total;
};

const getTotal = (product: Product) => {
  const quantity: number = product.quantity;
  const price: number = product.price;
  const total: number = Number(quantity * price) as number;
  return total;
};

function useCart() {
  const [cookie] = useCookies();
  const savedCart: Product[] = retrieveCart(cookie["alflora-user"]);
  const [cart, setCart] = useState<any[]>(savedCart);
  const [total, setTotal] = useState<number>(getTotalCart(savedCart));
  const [cartOpen, cartModalTools] = useModal();

  //Cart Management
  const showCart = () => {
    if (!cartOpen) {
      cartModalTools.open();
      setTimeout(() => {
        cartModalTools.close();
      }, 2500);
    }
  };

  const realSetCart = (newCart: Product[]) => {
    setCart(newCart);
    setTotal(getTotalCart(newCart));
    saveCart(newCart, cookie["alflora-user"]);
  };

  const reset = () => {
    setCart([]);
  };

  //Utils
  const getProductById = (id: number) => {
    const found = cart.find((product) => product.id === id);
    return found;
  };

  const patch = (product: Product | any) => {
    let cartCopy = cart;
    const id = product.id;
    let productIdx = cart.findIndex((cartProd: Product) => cartProd.id === id);
    cartCopy[productIdx] = product;
    realSetCart(cartCopy);
  };

  //Main Operations
  const add = (product: Product, isShowCart: boolean = false) => {
    const existsAlready = checkForProduct(product.id, cart);
    const total = Number(getTotal(product));
    if (existsAlready) {
      patch({ ...product, total });
    } else {
      realSetCart([...cart, { ...product, total }]);
    }
    if (isShowCart) showCart();
  };

  const del = (id: number) => {
    let cartCopy = cart.filter((cartProd: Product) => cartProd.id !== id);
    realSetCart(cartCopy);
  };

  const bind = {
    //Cart Modal Management
    cartOpen,
    showCart,
    toggleCart: cartModalTools.toggle,
    closeCart: cartModalTools.close,
    //Operations
    add,
    del,
    reset,
    //Total
    total,
    //Utils
    getProductById,
    totalItems: cart.length,
    removeCart: () => {
      removeCart(cookie["alflora-user"]);
      setTotal(0);
      setCart([]);
    },
  };

  return [cart, bind];
}

const CartProvider: React.FC = ({ children }) => {
  const [cart, bind] = useCart();

  return <CartContext.Provider value={[cart, bind]}>{children}</CartContext.Provider>;
};

export default CartProvider;
