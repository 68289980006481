import axios, { AxiosResponse } from 'axios';

const getCountriesById = async (key: string, id: number, onSuccess) => {
  const url = `${process.env.REACT_APP_URL}/countries`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${key}`,
    },
    params: {
      limit: 5,
      populate: 'regions',
      where: { id },
    },
  };

  try {
    const res: AxiosResponse = await axios.get(url, config);
    onSuccess(res.data[0]);
  } catch (err) {
    console.log(err);
  }
};

export default getCountriesById;
