import styled from 'styled-components';
import background from '../images/alf3.jpg';

const HeaderContainer = styled.div`
  padding-top: 5px;
  width: 100%;
  height: 210px;
  position: relative;
  background-position-x: left;
  background-position: bottom;
  background-image: url(${background});
  @media (max-width: 1024px) {
    display: none;
  }
`;

const IconButtons = styled.button`
  padding: 2rem 1rem;
  text-align: center;
  transition: 0.3s linear;
  font-weight: 600;
  user-select: none;
  &:focus {
    outline: none;
  } 
  ${(props) => (props.nopointer ? `pointer-events: none; cursor: default;` : ``)}
  ${(props) => props.isOpen && `background-color: darkseagreen;`}
  ${(props) =>
    props.reverse
      ? `color: white; background-color: ${process.env.REACT_APP_BGCOLOR};`
      : `color: black; &:hover {
        background-color: ${process.env.REACT_APP_BGCOLOR};
    color: white;
  }  
 
  `}
`;
const IconLink = styled.a`
  padding: 2rem 1rem;
  text-align: center;
  transition: 0.3s linear;
  font-weight: 600;
  user-select: none;
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.reverse
      ? `color: white; background-color:  ${process.env.REACT_APP_BGCOLOR};`
      : `color: black; &:hover {
    background-color: ${process.env.REACT_APP_BGCOLOR};
    color: white;
  }`}
`;

const TopInfoContainer = styled.div`
  font-size: 16px;
  z-index: 10;
  color: white;
  margin-bottom: 0.25rem;
  margin-right: 1.5rem;
  margin-left: auto;
  font-weight: 600;
`;

const InnerContainer = styled.div`
  margin: auto;
  width: 85vw;
  margin-top: 9px;
  margin-bottom: auto;
  background-color: white;
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 100px;
  padding-right: 40px;
`;

const SearchInput = styled.input`
  position: absolute;
  background-color: white;
  z-index: 20;
  background-color: #fafafa;
  color: #2c3e50;
  height: 100%;
  font-size: 22px;
  transition: 0.2s linear;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #2c3e50;
  }
  ${(props) => (props.isOpen ? ` left: -264px; width: auto; padding: 1rem;` : `width: 0px; padding: 0; left: -127px;`)}
`;

const Logo = styled.img`
  height: 150px;
  width: 150px;
  z-index: 21;
  left: 5vw;
  position: absolute;
`;

export { Logo, SearchInput, InnerContainer, IconLink, IconButtons, HeaderContainer, TopInfoContainer };
