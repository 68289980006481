export const removeFromArray = (val: any, array: any[]) => {
  const newArray = array.filter((oldVal) => {
    return oldVal !== val;
  });

  return newArray;
};

export const validateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const isB2B = (entity) => {
  const isB2B: boolean = entity && entity !== 'null' && entity !== undefined;
  if (isB2B) {
    return true;
  } else return false;
};

export const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const cleanOrderData = (cart, paymentMethod = 'paypal') => {
  let object = {
    items: [],
  };
  cart.forEach((product) => {
    let newObj = {
      product: product.id,
      quantity: product.quantity,
      price: product.price,
      discount: 0,
      //discount: getDiscount(product),
    };
    object.items.push(newObj);
  });

  return object;
};
