import styled from 'styled-components';

const Button = styled.button`
  padding: 10px 7px;
  border: 1px solid #3b6f5b;
  width: 100%;
  border-radius: 2px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.4s ease;
  background-color: ${process.env.REACT_APP_2COLOR};
  letter-spacing: 1.5px;
  &:focus {
    outline: none;
  }
  @media (max-width: 500px) {
    width: 100%;
    margin-top: 30px;
  }
`;
const Image = styled.img`
  width: auto;
  min-height: 350px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 425px) {
    min-height: 250px !important;
  }
`;

const Title = styled.h2`
  color: ${process.env.REACT_APP_2COLOR};
  text-transform: uppercase;
  margin-top: 0;
  font-size: 28px;
  letter-spacing: 1px;
  @media (max-width: 425px) {
    font-size: 20px !important;
  }
`;
const Price = styled.p`
  color: ${process.env.REACT_APP_2COLOR};
  font-weight: 600;
  font-size: 22px;
  @media (max-width: 425px) {
    font-size: 20px !important;
  }
`;
const Ref = styled.p`
  text-transform: uppercase;
  padding: 10px 0;
  font-size: 20px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #ccc;
  margin-bottom: 2rem;
`;

const ImageDiv = styled.div`
  width: 100%;
  position: relative;
  max-height: 560px;
  border: 1px solid #88888859;
  overflow-y: auto;
  @media (max-width: 1024px) {
    border: none;
    max-height: 250px;
  }
  @media (max-width: 425px) {
    max-height: 200px !important;
  }
`;

export { ImageDiv, Ref, Price, Image, Title, Button };
