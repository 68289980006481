import axios, { AxiosResponse } from "axios";

const login = async (setLogData, data, onSuccess, onFail) => {
  const url: string | undefined = process.env.REACT_APP_URL;
  const fullUrl: string = `${url}/login`;

  try {
    const res: AxiosResponse = await axios.post(fullUrl, data);
    setLogData(res.data.user);
    onSuccess(res);
  } catch (err) {
    onFail(err);
  }
};

export default login;
