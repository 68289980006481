import React, { useRef } from 'react';
import Input from '../../small/Input';
import { Properties } from '../../../../hooks/useRegister';
import { CountryObject } from '../../../../api/countries';
import styled from 'styled-components';

const SelectorDiv = styled.div`
  background-color: #fafafa;
  position: absolute;
  top: 50px;
  width: 100%;
  border: 1px solid #333;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

const Option = styled.button`
  text-align: left;
  width: 100%;
  font-size: 15px;
  padding: 4px 7px;
  transition: 0.2s ease;
  &:focus {
    outline: none;
    background: #ccc;
  }
  &:hover {
    background: #ccc;
  }
`;

type CountryInputPropTypes = {
  wrong: boolean;
  properties: Properties;
  readOnly?: boolean;
  bind: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
};

const CountryInput: React.FC<CountryInputPropTypes> = ({ bind, wrong, properties, readOnly = false }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  let refArray = [];

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const code = e.keyCode;
    if (code === 40 && refArray[0]) {
      e.preventDefault();
      refArray[0].focus();
    }
  };

  const onOptionKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>, idx: number) => {
    const code = e.keyCode;
    const isRefReady = inputRef && inputRef.current;
    if (code === 40 && refArray[idx + 1]) {
      e.preventDefault();
      refArray[idx + 1].focus();
    } else if (code === 40 && !refArray[idx + 1] && isRefReady) {
      e.preventDefault();
      inputRef.current.focus();
    } else if (code === 38 && refArray[idx - 1]) {
      e.preventDefault();
      refArray[idx - 1].focus();
    } else if (code === 38 && !refArray[idx - 1] && isRefReady) {
      e.preventDefault();
      inputRef.current.focus();
    }
  };

  return (
    <div className="relative">
      <Input {...bind} wrong={wrong} ref={inputRef} placeholder="País*" onKeyDown={onInputKeyDown} readOnly={readOnly}/>
      {properties.countryList && properties.countryList.length > 0 && (
        <SelectorDiv>
          {properties.countryList.map((country: CountryObject, idx: number) => (
            <Option
              key={idx}
              ref={(ref) => (refArray[idx] = ref)}
              onClick={() => properties.set.setCountry(country)}
              onKeyDown={(e) => onOptionKeyDown(e, idx)}
            >
              {country.name}
            </Option>
          ))}
        </SelectorDiv>
      )}
    </div>
  );
};

export default React.memo(CountryInput);
