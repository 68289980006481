import axios from 'axios';

const validateToken = async (token, cb1 = (data) => {}, cb2 = (err) => {}) => {
  const baseUrl = process.env.REACT_APP_URL;
  const fullUrl = `${baseUrl}/auth/reset`;
  const config = {
    params: {
      token,
    },
  };

  try {
    const res = await axios.get(fullUrl, config);
    cb1(res.data);
    return true;
  } catch (err) {
    cb2(err);
    return false;
  }
};

export default validateToken;
