import { useState } from 'react';

const getOpposite = (dir: string) => {
  if (dir === 'ASC') {
    return 'DESC';
  } else if (dir === 'DESC') {
    return 'ASC';
  }
};

const useSort = (initialVal: string = 'id ASC') => {
  const [currentSort, setCurrentSort] = useState<string>(initialVal);

  const changeSort = (name: string, cb = () => {}) => {
    let sortItem: string = currentSort.split(' ')[0];
    let sortDir: string = currentSort.split(' ')[1];
    if (sortItem === name) {
      sortDir = getOpposite(sortDir);
    } else {
      sortDir = 'ASC';
      sortItem = name;
    }

    let fullSort: string = [sortItem, sortDir].join(' ');
    setCurrentSort(fullSort);
    cb();
  };

  const checkSortStatus = (name: string) => {
    let sortItem: string = currentSort.split(' ')[0];
    let sortDir: string = currentSort.split(' ')[1];
    if (sortDir === 'ASC' && sortItem === name) {
      return 'UP';
    } else if (sortDir === 'DESC' && sortItem === name) {
      return 'DOWN';
    } else {
      return '';
    }
  };

  const resetSort = () => {
    setCurrentSort('id ASC');
  };

  const directSelect = (val: string) => {
    setCurrentSort(val);
  };

  const checkWhatSort = (sortName: string) => {
    let sortItem: string = currentSort.split(' ')[0];
    let sortDir: string = currentSort.split(' ')[1];
    let isMatch = false;
    if (sortName === sortItem) {
      isMatch = true;
    }

    if (isMatch) {
      return sortDir;
    } else return '';
  };

  const sortTools = {
    resetSort,
    directSelect,
    checkWhatSort,
  };

  return { currentSort, changeSort, checkSortStatus, sortTools };
};

export default useSort;

//const checkActive = (name: string) => {
//  let sortItem: string = currentSort.split(" ")[0];
//  if (sortItem === name) {
//    return true;
//  } else {
//    return false;
//  }
//};
