import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

type HeaderLinkProps = {
  name: string;
  to: string;
  alignRight?: boolean;
};

const HeaderAnchor = styled(Link)`
  padding: 2rem 2rem;
  text-transform: uppercase;
  color: black;
  text-align: center;
  transition: 0.3s linear;
  font-weight: 600;
  user-select: none;
  width: 16.6%;
  &:hover {
    background-color: ${process.env.REACT_APP_BGCOLOR};
    color: white;
  }
  &:focus {
    outline: none;
  }
  ${props =>
    props.active ? `background-color: ${process.env.REACT_APP_BGCOLOR}; color: white;` : `color: black;`}
  ${props => props.alignright ? `margin-left:auto`: ``}
`;

const HeaderLink: React.FC<HeaderLinkProps> = ({ name, to, alignRight }) => {
  const path = useLocation().pathname;

  return (
    <HeaderAnchor to={to} alignright={alignRight} active={path === to}>
      {name}
    </HeaderAnchor>
  );
};

export default React.memo(HeaderLink);
