import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ size }) => {
    switch (size) {
      case 'medium':
        return `
        margin: 2rem 0;
        @media(min-width:426px){
          margin: 2rem 6rem 2rem 6rem;
        }
        padding: 40px 0;`;
      case 'small':
        return `
        margin: 2rem 0;
        @media(min-width:426px){
          margin: 2rem 8rem 2rem 8rem;
        }
        padding: 40px 0;`;
      case 'large':
        return `
        margin: 2rem 0;
        @media(min-width:426px){
          margin: 2rem 4rem 2rem 4rem;
        }
        padding: 40px 0;`;
    }
  }}

  ${({css}) => css && `${css}`}
`;

type ContainerProps = {
  children?: any;
  size?: string;
  extraStyle?: object;
  css?: string
};

const Container: React.FC<ContainerProps> = ({ children, size = 'medium', extraStyle = {}, css = "" }) => {
  return (
    <Wrapper size={size} style={extraStyle} css={css}>
      {children}
    </Wrapper>
  );
};

export default React.memo(Container);
