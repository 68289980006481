import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import Container from '../../pieces/small/Container';
import GoBack from '../../pieces/small/GoBack';
import getPost from '../../../api/getPost';
import { PostsData } from '../Posts/Posts';
import { Title, DateP } from './styled';
import { useCookies } from 'react-cookie';
import useLoading from '../../../hooks/useLoading';
import LoadingSpinner from '../../pieces/small/LoadingSpinner';

const containerCss: string = `
 @media(max-width: 1024px){
  margin: 10px 20px;
  padding: 10px 0;
 }
`;

const PostDetail: React.FC = () => {
  const [post, setPost] = useState<PostsData | null>(null);
  const [loading, loadTools] = useLoading(true);
  const [cookie] = useCookies();

  const { id } = useParams<number | string>();
  const API_KEY: string | undefined = cookie['alflora-key'];

  const setData = (data: PostsData) => {
    let date: Date = new Date(data.createdAt);
    let prettyDate: string = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
    data.createdAt = prettyDate;
    setPost(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadTools.start();
    getPost(cookie['alflora-key'], id, (post: PostsData) => {
      setData(post);
      loadTools.loaded();
    });
  }, [id, cookie]);

  return API_KEY ? (
    <Container size="small" css={containerCss}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="w-full text-right mb-0">
            <GoBack />
          </div>
          <Title>{post && post.heading}</Title>
          <DateP>{post && post.createdAt}</DateP>
          <div dangerouslySetInnerHTML={{ __html: post && post.body }} className="text-gray-800 post-html"></div>
        </>
      )}
    </Container>
  ) : (
    <Redirect to="/login" />
  );
};

export default React.memo(PostDetail);
