import './Filter.css';
import FilterItem from './FilterItem';
import React, { useState, useEffect } from 'react';
import { removeFromArray } from '../../../../utils';
import { ProductCategories } from '../../../../api/getProductCategories';
import { FilterContainer, FilterDiv, FilterInput, FilterTitle, FiltersDiv, ButtonsDiv, FilterButton } from './styled';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { FontAwesomeIcon } from '../../../pages/Contactos/styled';
import { faArrowDown, faChevronDown, faSkull } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

const MobileFilterWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  position: relative;
  padding: 15px 10px;
  flex-direction: row;
  background-color: #f1f1f1;
  z-index: 2;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  @media (min-width: 1025px) {
    display: none;
  }
`;

const MobileFilterButton = styled.button`
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  color: white;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: ${process.env.REACT_APP_2COLOR};
  border: 2px solid ${process.env.REACT_APP_2COLOR};
  ${({ css }) => css && `${css}`}
`;

const FilterOpenedDiv = styled.div`
  position: absolute;
  width: 100vw;
  min-height: 80px;
  background-color: #f1f1f1fa;
  padding: 0 10px 15px 10px;
  transition: 0.2s linear;
  top: 60px;
  display: flex;
  flex-direction: column;
  left: 0;
  ${({ open }) => !open && `display: none`}
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin: auto;
  font-size: 25px;
  color: #6b6b6b;
  transition: 0.3s linear;
  ${({ open }) => open && `transform: rotate(-180deg)`}
`;

const MobileSearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 4px 10px;
  border: 1px solid #ccc;
  &:focus {
    outline: none;
  }
  ${({ css }) => css && `${css}`}
`;

type FilterPropTypes = {
  categories: ProductCategories[];
  resetPage: () => void;
  type?: string;
  bind: any;
  filterTools: any;
};

const cleanFilterCss = `background-color: white;color: ${process.env.REACT_APP_BGCOLOR};`;

const Filter: React.FC<FilterPropTypes> = ({ categories, bind, filterTools, resetPage, type = 'normal' }) => {
  return type === 'normal' ? (
    <FilterDiv>
      <FilterContainer>
        <FilterTitle>Filtros</FilterTitle>
        <FilterInput placeholder="Pesquisa por nome..." {...bind.name} />
        <FilterInput placeholder="Pesquisa por código..." {...bind.sku} />
        <FiltersDiv>
          {categories &&
            categories.map((category: ProductCategories, idx) => {
              return <FilterItem category={category} key={idx} {...bind.filterItems(category.external_id)} />;
            })}
        </FiltersDiv>
        <ButtonsDiv>
          {filterTools.isEmpty ? (
            <FilterButton onClick={() => filterTools.apply(resetPage)}>Filtrar</FilterButton>
          ) : (
            <>
              <div className="w-1/2 pr-1">
                <FilterButton onClick={() => filterTools.apply(resetPage)}>Filtrar</FilterButton>
              </div>
              <div className="w-1/2 pl-1">
                <FilterButton onClick={() => filterTools.reset(resetPage)}>Limpar</FilterButton>
              </div>
            </>
          )}
        </ButtonsDiv>
      </FilterContainer>
    </FilterDiv>
  ) : (
    <MobileFilterWrapper>
      <div className="w-11/12 pr-3">
        <MobileSearchInput placeholder="Pesquisa por nome..." {...bind.name} />
      </div>
      <div className="w-1/12 flex flex-row">
        <ArrowIcon icon={faChevronDown} onClick={filterTools.modal.toggle} open={filterTools.filterOpen} />
      </div>
      <FilterOpenedDiv open={filterTools.filterOpen}>
        <div className="flex flex-col mb-4">
          <MobileSearchInput
            placeholder="Pesquisa por código..."
            {...bind.sku}
            css="margin-bottom: 10px !important;width: calc(100% - 8px);"
          />
          {categories &&
            categories.map((category: ProductCategories, idx) => {
              return <FilterItem category={category} key={idx} {...bind.filterItems(category.external_id)} />;
            })}
        </div>
        <div className="flex flex-row mt-auto">
          {filterTools.isEmpty ? (
            <MobileFilterButton onClick={() => filterTools.mobileApply(resetPage)}>Filtrar</MobileFilterButton>
          ) : (
            <>
              <div className="w-1/2 pr-1">
                <MobileFilterButton onClick={() => filterTools.mobileReset(resetPage)} css={cleanFilterCss}>
                  Limpar
                </MobileFilterButton>
              </div>
              <div className="w-1/2 pl-1">
                <MobileFilterButton onClick={() => filterTools.mobileApply(resetPage)}>Filtrar</MobileFilterButton>
              </div>
            </>
          )}
        </div>
      </FilterOpenedDiv>
    </MobileFilterWrapper>
  );
};

export default React.memo(Filter);
