import styled from 'styled-components';
import { Link as L } from 'react-router-dom';

const Link = styled(L)`
  color:  ${process.env.REACT_APP_2COLOR};
  font-weight: 600;
`;

const PassP = styled.p`
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export { Link, PassP };
