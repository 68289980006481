import React, { useEffect } from "react";
import Input from "../../pieces/small/Input";
import CountryInput from "../../pieces/medium/CountryInput/CountryInput";
import useRegister from "../../../hooks/useRegister";
import Button from "../../pieces/small/Button";
import putUser from "../../../api/putUser";
import { useCookies } from "react-cookie";
import { Row, Column } from "../../../styledMain";
import { User } from "../../../api/postUser";

type AccountDataProps = {
  current: string;
  account: User;
};

const AccountData: React.FC<AccountDataProps> = ({ current, account }) => {
  const { bind, properties } = useRegister({ noPassword: true });
  const [cookie] = useCookies();

  const section = "dados_pessoais";

  const updateUser = () => {
    const hasErrors = properties.error.checkForErrors();
    if (!hasErrors) {
      const dataToPut = {
        ...properties.dataToPut,
      };
      putUser(cookie["alflora-key"], cookie["alflora-user"].id, dataToPut, () => {});
    }
  };

  useEffect(() => {
    if (account) properties.retrieve(account);
  }, [current]);

  return (
    current === section && (
      <div className="w-full mt-10">
        <Input placeholder="Email*" {...bind.email} wrong={properties.error.hasError("email")} readOnly={true} />
        <Input placeholder="Nome*" {...bind.name} wrong={properties.error.hasError("name")} readOnly={true} />
        <Input placeholder="NIF*" {...bind.vatNumber} wrong={properties.error.hasError("vatNumber")} type="number" readOnly={true} />
        <Input placeholder="Telefone*" {...bind.mobilePrivate} wrong={properties.error.hasError("mobilePrivate")} type="number" readOnly={true} />
        <Row>
          <Column className="w-3/12 pr-2" css="@media(max-width: 500px){width: 100% !important; padding: 0;}">
            <CountryInput bind={bind.country} wrong={properties.error.hasError("country")} properties={properties} readOnly={true} />
          </Column>
          <Column className="w-5/12 px-2" css="@media(max-width: 500px){width: 50% !important; padding-left: 0;}">
            <Input placeholder="Morada*" {...bind.addressLine1} wrong={properties.error.hasError("addressLine1")} readOnly={true} />
          </Column>
          <Column className="w-4/12 pl-2" css="@media(max-width: 500px){width: 50% !important;}">
            <Input placeholder="Morada (cont.)" {...bind.addressLine2} readOnly={true} />
          </Column>
        </Row>
        <Row>
          <div className="w-4/12 pr-2">
            <Input placeholder="Código Postal*" {...bind.postalCode} wrong={properties.error.hasError("postalCode")} readOnly={true} />
          </div>
          <div className="w-8/12 pl-2">
            <Input placeholder="Cidade" {...bind.city} readOnly={true} />
          </div>
        </Row>
        
      </div>
    )
  );
};

export default React.memo(AccountData);
//<Button onClick={updateUser}>Guardar</Button>