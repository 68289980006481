import React from 'react';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductCategories } from '../../../../api/getProductCategories';

const FilterName = styled.span`
color: #333;
`

const Checkmark = styled.div`
  height: 25px;
  width: 25px;
  margin-right: 15px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #333;
  transition: 0.15s ease;
  ${(props) => (props.checked ? `background-color: ${process.env.REACT_APP_BGCOLOR};` : ``)}
`;

const Check = styled(FontAwesomeIcon)`
  transition: 0.15s ease;
  position: relative;
  font-size: 16px;
  color: white;
  width: 20px !important;
  top: -1px;
  left: calc(50% - 10px);
  ${(props) => (props.checked ? `opacity: 1` : `opacity: 0`)}
`;

const FilterItemDiv = styled.div`
  margin: 10px 0;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.7px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  background: #cccccc45;
  padding: 5px;
  transition: 0.2s linear;
  @media (max-width: 1024px) {
    display: none;
  }
  &:hover {
   background: #33333342
  }
`;

const MobileFilterItemDiv = styled.div`
  display: flex;
  padding: 10px 0;
  flex-direction: row;
  border-bottom: 1px solid #2f2f2f;
  text-transform: uppercase;
  font-weight: 900;
  color: #524e4e;
  letter-spacing: 0.6px;
  @media (min-width: 1025px) {
    display: none !important;
  }
`;

const MobileCheckbox = styled.div`
  margin-left: auto;
  height: 25px;
  width: 25px;
  background-color: white;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: 0.2s linear;
  ${({ checked }) => checked && `background-color: ${process.env.REACT_APP_BGCOLOR};`}
`;

const MobileCheck = styled(FontAwesomeIcon)`
  color: white;
  position: relative;
  left: 4px;
  top: 0px;
  transition: 0.2s linear;
  ${({ checked }) => (checked ? `opacity: 1;` : `opacity: 0`)}
`;

type FilterItemPropTypes = {
  category: ProductCategories;
  onClick: any;
  active: boolean;
};

const FilterItem: React.FC<FilterItemPropTypes> = ({ category, onClick, active }) => {
  return (
    <>
      <FilterItemDiv onClick={onClick} className="filter-item">
        <Checkmark className="checkbox" checked={active}>
          <Check icon={faCheck} className="icon" checked={active} />
        </Checkmark>
        <FilterName>{category.name}</FilterName>
      </FilterItemDiv>
      <MobileFilterItemDiv>
        <span>{category.name}</span>
        <MobileCheckbox checked={active} onClick={onClick}>
          <MobileCheck icon={faCheck} checked={active} />
        </MobileCheckbox>
      </MobileFilterItemDiv>
    </>
  );
};

export default React.memo(FilterItem);
