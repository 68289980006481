import styled from 'styled-components';

const FilterDiv = styled.div`
  width: 100%;
  margin: 1rem 1rem 1rem 0;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const FilterContainer = styled.div`
  margin: 10px 15px;
`;

const FilterInput = styled.input`
  border: 1px solid #ccc;
  padding: 4px 10px;
  background-color: #cccccc4f;
  width: 100%;
  transition: 0.1s ease;
  margin: 5px 0;
  &::placeholder {
    color: #333;
  }
  &:focus {
    outline: none;
    border-color: #546156;
  }
`;

const FiltersDiv = styled.div`
`;

const FilterTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0.5rem;
  letter-spacing: 0.8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #cccccc82;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 22px;
  letter-spacing: 1px;
  color: ${process.env.REACT_APP_2COLOR};
`;

const FilterButton = styled.button`
  background-color: ${process.env.REACT_APP_2COLOR};
  border-radius: 3px;
  width: 100%;
  padding: 7px 5px;
  color: white;
  transition: 0.2s ease;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #264c2c;
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 15px;
  transition: 0.15s linear;
`;

export { FilterDiv, FilterContainer, FilterInput, FiltersDiv, FilterTitle, FilterButton, ButtonsDiv };
