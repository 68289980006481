import axios, { AxiosResponse } from "axios";

const getAccount = async (key: string, cb1 = (data) => {}, cb2 = (err) => {}) => {
  const baseUrl: string = process.env.REACT_APP_URL;
  const fullUrl: string = `${baseUrl}/account`;

  const config = {
    headers: {
      Authorization: `Bearer ${key}`,
    },
  };

  try {
    const res: AxiosResponse = await axios.get(fullUrl, config);
    cb1(res.data);
  } catch (err) {
    cb2(err);
  }
};

export default getAccount;
