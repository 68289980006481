import React from "react";
import styled from "styled-components";

type AbousUsButtonProps = {
  id: string;
  children?: any;
  current: string;
  size?: string;
  onClick: (e: React.MouseEvent) => any | void;
};

const Button = styled.button`
  background-color: #f5f5f5;
  text-transform: uppercase;
  padding: 20px 0;
  width: 100%;
  font-size: 19px;
  font-weight: 500;
  transition: 0.4s linear;
  user-select: none;
  cursor: unset;
  &:hover {
    background-color: ${process.env.REACT_APP_2COLOR};
    color: white;
  }
  &:focus{
    outline: none;
  }
  @media(max-width: 768px){
    font-size: 16px;
  }
  @media(max-width: 600px){
    font-size: 14px;
  }
  @media(max-width: 500px){
    font-size: 12px;
  }
  ${props =>
    props.active &&
    `background-color: ${process.env.REACT_APP_2COLOR}; 
  color: white;`}
`;

const AboutUsButton: React.FC<AbousUsButtonProps> = ({
  onClick,
  children,
  id,
  size = "w-1/3" ,
  current
}) => {
  return (
    <div className={`${size} m-1`}>
      <Button onClick={onClick} id={id} active={current === id ? true : false}>
        {children}
      </Button>
    </div>
  );
};

export default React.memo(AboutUsButton);
