import React, { useEffect, useState } from "react";
import useCart from "../../../hooks/useCart";
import Container from "../../pieces/small/Container";
import CheckoutRow from "./CheckoutRow";
import { Product } from "../NewProducts/types";
import { cleanOrderData } from "../../../utils";
import { useHistory } from "react-router-dom";
import createOrder from "../../../api/createOrder";
import { useCookies } from "react-cookie";
import { Row, Column } from "../../../styledMain";
import { TextArea, CheckoutH, TH, TR, PaymentP, PaymentWrapper } from "./styled";
import Button from "../../pieces/small/Button";
import useInput from "../../../hooks/useInput";
import useErrors from "../../../hooks/useErrors";
import OrderSuccessRow from "./OrderSuccessRow";
import LoadingSpinner from "../../pieces/small/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { StockWarning } from "../NewProducts/styled";

const extraStyle = { marginTop: "0rem" };

const leftContainerCss: string = `
  width: 62.5%;
  padding-right: 30px;
  @media(max-width: 1024px) { 
    width: 100%;
    padding-right: 0;
  }
`;
const rightContainerCss: string = `
  width: 37.5%;
  padding-left: 30px;
  @media(max-width: 1024px) { 
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const quantityThCss: string = `@media(max-width: 768px) { 
  color: white;
  font-size: 1px;
}`;

const MinimumWarning = styled.p`
  margin-top: 0;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
`;

const Checkout = () => {
  const [cart, cartTools] = useCart();
  const [notes, bindNotes] = useInput("");
  //Utils
  const [cartToShow, setCartToShow] = useState<Product[]>(null);
  const [totalToShow, setTotalToShow] = useState<number>(0);
  const [orderComplete, setOrderComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { errorTools } = useErrors();
  const [cookie] = useCookies();
  const history = useHistory();

  const order = () => {
    let orderPayload = Object.assign(cleanOrderData(cart), { notes });
    setLoading(true);
    createOrder(
      cookie["alflora-key"],
      orderPayload,
      (res) => {
        setCartToShow(cart);
        setTotalToShow(cartTools.total);
        cartTools.removeCart();
        setOrderComplete(true);
        setLoading(false);
      },
      () => {
        errorTools.addTimeout("order-error");
        setLoading(false);
      }
    );
  };

  const proceed = () => {
    history.push("/home");
  };

  useEffect(() => {
    if (cartTools.totalItems === 0 && !orderComplete) history.push("/home");
  }, [cartTools.totalItems]);

  if (!cookie["alflora-user"]) history.push("/login");

  return !orderComplete ? (
    <Container size="small" extraStyle={extraStyle} css="@media(max-width: 1024px){margin: 0vh 2vw;padding-top: 20px;}">
      {cart.length === 0 ? null : loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <StockWarning>Preço sem IVA à taxa legal em vigor</StockWarning>
          <CheckoutH>Encomenda</CheckoutH>
          <Row css="flex-wrap: wrap;">
            <Column css={leftContainerCss}>
              <table>
                <thead>
                  <TR>
                    <TH colSpan={3} className="text-left">
                      Produto
                    </TH>
                    <TH className="text-right">Preço</TH>
                    <TH css={quantityThCss}>Quantidade</TH>
                    <TH className="text-right">Total</TH>
                    <TH className="text-right"></TH>
                  </TR>
                </thead>
                <tbody>
                  {cart.map((product: Product, idx: number) => (
                    <CheckoutRow product={product} key={idx} />
                  ))}
                </tbody>
              </table>
            </Column>
            <Column css={rightContainerCss}>
              <PaymentWrapper>
                <PaymentP css="margin-top: 20px;">
                  <strong>Envio:</strong>
                  <span className="ml-auto">0.00€</span>
                </PaymentP>
                <PaymentP>
                  <strong>Subtotal:</strong>
                  <span className="ml-auto">{parseFloat(cartTools.total).toFixed(2)}€</span>
                </PaymentP>
                <PaymentP>
                  <strong>Total:</strong>
                  <span className="ml-auto">{parseFloat(cartTools.total).toFixed(2)}€</span>
                </PaymentP>
                <TextArea placeholder="Observações..." {...bindNotes} />
                {parseFloat(cartTools.total) < 100 && <MinimumWarning>Minimo de 100€ requirido para efetuar encomenda</MinimumWarning>}
                <Button extraCss="margin: auto 0 0 0;" onClick={order} disabled={parseFloat(cartTools.total) < 100}>
                  Encomendar
                </Button>
              </PaymentWrapper>
            </Column>
          </Row>
        </>
      )}
    </Container>
  ) : (
    <Column css="padding: 20px 10vw;@media(max-width: 525px){padding: 20px 5vw;}">
      <CheckoutH>Encomenda Efetuada com Sucesso</CheckoutH>
      <table>
        <thead>
          <TR>
            <TH colSpan={3} className="text-left">
              Produto
            </TH>
            <TH className="text-right">Preço</TH>
            <TH css={quantityThCss}>Quantidade</TH>
            <TH className="text-right">Total</TH>
          </TR>
        </thead>
        <tbody>
          {cartToShow.map((product: Product) => (
            <OrderSuccessRow product={product} />
          ))}
          <tr>
            <td colSpan={6} className="text-right font-semibold pt-3">
              Total: {parseFloat(String(totalToShow)).toFixed(2)}€
            </td>
          </tr>
        </tbody>
      </table>
      <Button onClick={proceed} extraCss="max-width: 40vh;margin: 20px 0 0 auto;@media(max-width: 768px){max-width: 100%;}">
        Continuar
      </Button>
    </Column>
  );
};

export default Checkout;
