import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Button = styled.button`
  text-transform: uppercase;
  font-weight: 600;
  color: #333;
  &:focus {
    outline: none;
  }
`;

export const Arrow = styled(FontAwesomeIcon)`
  color: #333;
  font-size: 18px;
  transition: 0.3s linear;
  ${({ status }) => status === 'UP' && `transform: rotate(180deg)`}
`;
