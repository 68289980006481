import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BuyButton from '../../small/BuyButton';
import NumberInput from '../NumberInput/NumberInput';
import fallback from '../../../../images/fallback.png';
import { Image, Price, CardContainer, Ref, Name, ImageDiv, ProductFrame, ProductDetailWrapper } from './styled';
import useNumberInput from '../../../../hooks/useNumberInput';
import useCart from '../../../../hooks/useCart';
import styled from 'styled-components';

const ImageLink = styled(Link)`
  width: 33.3%;
`;

type CardProps = {
  product: {
    sku?: string | undefined;
    barcode?: string | undefined;
    name: string | undefined;
    description?: string | undefined;
    price?: number | undefined;
    stock?: number | undefined;
    image_main?: string | undefined;
    image_thumb?: string | undefined;
    id: number | undefined;
    createdAt?: string | undefined;
    updatedAt?: string | undefined;
  };
};

const baseImgUrl: string = 'https://alflora.com/assets/img/ART';

const ProductCard: React.FC<CardProps> = ({ product }) => {
  const [quantity, operator, onChange] = useNumberInput(1);
  const [realSrc, setRealSrc] = useState<string>(`${baseImgUrl}/${product.image_main}`);
  const [, cartTools] = useCart();

  const handleFallback = (e) => {
    e.target.style.objectFit = 'contain';
    setRealSrc(fallback);
  };

  const handleBuy = () => {
    cartTools.add({ ...product, quantity }, true);
  };

  useEffect(() => {
    setRealSrc(`${baseImgUrl}/${product.image_main}`);
  }, [product]);

  return (
    <ProductFrame>
      <CardContainer>
        <ImageLink to={`/produto-detalhe/${product.id}`}>
          <ImageDiv className="w-full overflow-y-hidden">
            <Image src={realSrc} alt="product" onError={handleFallback} />
          </ImageDiv>
        </ImageLink>
        <ProductDetailWrapper>
          <Link to={`/produto-detalhe/${product.id}`}>
            <Name>{product.name} </Name>
          </Link>
          <Ref>
            <span className="font-bold">ref:</span>
            {product.sku}
          </Ref>
          <Price>{product.price}€</Price>
          <div className="w-full flex flex-row mb-4 mt-auto">
            <div className="w-3/4">
              <NumberInput value={quantity} onChange={onChange} operator={operator} />
            </div>
            <div className="w-1/4 px-2">
              <BuyButton onClick={handleBuy} />
            </div>
          </div>
        </ProductDetailWrapper>
      </CardContainer>
    </ProductFrame>
  );
};

export default React.memo(ProductCard);
