import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../styledMain';

const Title = styled.h4`
  font-size: 20px;
  font-weight: 400;
  margin-top: 0 !important;
`;

const texts = {
  empresa: {
    title: 'Empresa',
    side: 'left',
    jsx: (
      <div className="about-us-texts">
        <Title>Empresa</Title>
        <Text>
          A Alflora , designa um vasto portfolio alusivo à corrente Artítistica & Decorativa, confinada a Decoradores, Floristas e
          Arquitectos, entre outras vertentes profissionais. As soluções decorativas e artísticas definem em absoluto, a aliança da
          Qualidade à Distinção, permitindo à Alflora selar este desejo.
        </Text>
        <Text>
          A relevância do seu negócio é oriunda da significativa selecção de Produtos que se insurgem pela Inovação, Contemporaneidade e
          Singularidade. A importação do Mercado Europeu, Africano e Asiático, permitem uma melhor dispersão e compatibilidade das
          tendências mundiais da Decoração. Através destas características empresariais, somos um dos líderes no panorama nacional.
        </Text>
      </div>
    ),
  },
  espaco: {
    title: 'Espaço',
    side: 'left',
    jsx: (
      <div className="about-us-texts">
        <Title>Espaço</Title>
        <Text>
          Define um espaço marcante de referência nacional para quem designa o negócio/interesse na Arte Floral & Arte Decorativa. Possuímos
          um Showroom / Cash & Carry de 3000 m² , que permite demonstrar distintas soluções alusivas à Arte Decorativa, e um Salão-Atelier
          onde realizamos os eventos de Formação e Demonstração das atividades associadas ao sector da Decoração. Associado ao nosso
          portefólio comercial estão 25000 referências que se reconstituem todos os anos, incrementado a exclusividade e imparidade.
        </Text>
      </div>
    ),
  },
  missao_conceito: {
    title: 'Missão e Conceito',
    side: 'right',
    jsx: (
      <div className="about-us-texts">
        <Title>Missão e Conceito</Title>
        <Text>
          A Alflora objectiva a sua actividade para permitir uma liberdade de soluções artísticas e decorativas, aos seus estimados
          clientes.
        </Text>
        <Text>
          A selecção de Produtos é concretizada de forma estratégica, consoante as tendências e as correntes criativas. O rigor deste
          procedimento, categoriza-nos como um dos grandes marcos do sector Grossista da Decoração..
        </Text>
        <Text>Para nós, a Qualidade é um objectivo constante, e a Variedade um conceito renovado.</Text>
      </div>
    ),
  },
};

export { texts };
