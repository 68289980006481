import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const GoBackButton = styled.button`
  font-size: 16px;
  margin-left: auto;
  color: ${process.env.REACT_APP_2COLOR};
  text-transform: uppercase;
  font-weight: 700;
  &:focus {
    outline: none;
  }
  @media (max-width: 768px) {
  display: none;
  }
`;

function GoBack() {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <GoBackButton onClick={goBack}>
      <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
      Voltar
    </GoBackButton>
  );
}

export default React.memo(GoBack);
