import React from 'react';
import { PaginationWrapper, PageButton, SeeMoreButton } from './styled';

interface PaginationProps {
  empty?: boolean;
  pageInfo:
    | {
        pages: number[];
        actualPage: number;
        totalPages: number;
      }
    | any;
  onClick: any;
}

const Pagination: React.FC<PaginationProps> = ({ pageInfo, onClick, empty = false }) => {
  return (
    <div className="ml-auto">
      <PaginationWrapper>
        {pageInfo && !empty ? (
          <React.Fragment>
            {pageInfo.actualPage > 1 && (
              <PageButton id="1" onClick={onClick} notNumber={true}>
                Primeira
              </PageButton>
            )}
            {pageInfo.pages.map((page, idx) => (
              <PageButton onClick={onClick} id={page} key={idx} active={pageInfo.actualPage === page ? true : false}>
                {page}
              </PageButton>
            ))}
            {pageInfo.actualPage !== pageInfo.totalPages && (
              <PageButton id={pageInfo.totalPages} onClick={onClick} notNumber={true}>
                Última
              </PageButton>
            )}
          </React.Fragment>
        ) : null}
      </PaginationWrapper>
      
    </div>
  );
};

export default React.memo(Pagination);
//{pageInfo.actualPage !== pageInfo.totalPages && (
//  <SeeMoreButton id={pageInfo.actualPage + 1} onClick={onClick}>
//    Ver Mais
//  </SeeMoreButton>
//)}