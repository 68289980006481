import React from 'react';
import Container from '../../pieces/small/Container';
import { FontAwesomeIcon, Title, TextArea } from './styled';
import Input from '../../pieces/small/Input';
import Button from '../../pieces/small/Button';
import { faHome, faPhone, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Row, Column } from '../../../styledMain';
import styled from 'styled-components';

const InfoP = styled.p`
  margin-bottom: 2rem;
  transition: 0.1s linear;
  @media (max-width: 1024px) {
    margin-bottom: 1rem;
  }
`;

const InputDiv = styled.div`
  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
`;

const leftCss: string = `width: 33.3%;@media(max-width: 1024px){width: 100%;}`;
const rightCss: string = `width: 66.6%;@media(max-width: 1024px){width: 100%;}`;
const containerCss: string = `@media(max-width: 1024px){margin: 10px 20px;padding-top: 0px;}@media(max-width: 500px){margin: 10px;}`;
const rowCss: string = `@media(max-width: 1024px){flex-flow: column-reverse;}`;

const Contactos: React.FC = () => {
  return (
    <Container css={containerCss}>
      <Row css={rowCss}>
        <Column css={leftCss} className="informacao-contacto">
          <Title>INFORMAÇÃO CONTACTO</Title>
          <InfoP>
            <FontAwesomeIcon icon={faHome} /> R. Cidres 1263, 4455-442 Freixieiro-Perafita
          </InfoP>
          <InfoP>
            <FontAwesomeIcon icon={faMobileAlt} /> +351 964 391 904
          </InfoP>
          <InfoP>
            <FontAwesomeIcon icon={faPhone} /> +351 22 996 5820
          </InfoP>
          <InfoP>
            <FontAwesomeIcon icon={faEnvelope} /> info@alflora.com
          </InfoP>
        </Column>
        <Column css={rightCss} className="fale-conosco">
          <Title>FALE CONNOSCO!</Title>
          <Column>
            <Row>
              <InputDiv className="w-1/2 pr-4">
                <Input placeholder="O seu nome" className="shadow-md" />
                <Input placeholder="O seu endereço de email" className="shadow-md" />
              </InputDiv>
              <InputDiv className="w-1/2 pl-4">
                <Input placeholder="Telefone" className="shadow-md" />
                <Input placeholder="Empresa" className="shadow-md" />
              </InputDiv>
            </Row>
            <TextArea placeholder="Mensagem" className="shadow-md" />
            <Button>Enviar</Button>
          </Column>
        </Column>
      </Row>
    </Container>
  );
};

export default React.memo(Contactos);
