import styled from "styled-components";

const Title = styled.h2`
  color:  ${process.env.REACT_APP_2COLOR};
  text-transform: uppercase;
  font-size: 28px;
  margin-top: 0;
  letter-spacing: 0.8px;
`;

const DateP = styled.p`
  font-weight: 600;
`;

export { Title, DateP };
