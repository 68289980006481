import React, { useEffect, useRef } from 'react';
import { SidebarTools } from '.';
import navItems from './navItems';
import styled from 'styled-components';
import SidebarItem from '../components/pieces/small/SidebarItem';
import { useCookies } from 'react-cookie';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../components/pages/Contactos/styled';
import { User } from '../api/postUser';
import { useLocation, Link } from 'react-router-dom';

interface SidebarPropTypes {
  sidebarTools: SidebarTools;
  open: boolean;
  logout: () => void;
}

const SidebarWrapper = styled.div`
  width: 250px;
  background-color: #424242fa;
  height: 100vh;
  position: fixed;
  z-index: 101;
  display: flex;
  flex-direction: column;
  transition: 0.2s linear;
  left: -250px;
  top: 60px;
  ${({ open }) => (open ? `transform: translateX(250px)` : ``)}
`;

const BlackDiv = styled.div`
  position: fixed;
  background-color: #00000080;
  width: 100vw;
  left: 0;
  height: 100vh;
  top: 60px;
  z-index: 100;
  ${({ open }) => !open && `display: none;`}
`;

const UserName = styled.span`
  font-size: 16px;
  color: white;
  font-weight: 600;
`;

const SidebarButton = styled.button`
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  color: white;
  padding: 6px 10px;
`;

const UserIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 16;
  margin: auto 10px auto 0;
`;

const UserInfoDiv = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
`;

const Sidebar: React.FC<SidebarPropTypes> = ({ sidebarTools, open, logout }) => {
  const { pathname } = useLocation();
  const [cookie] = useCookies();

  const key: string = cookie['alflora-key'];
  const user: User = cookie['alflora-user'];

  useEffect(() => {
    const bodyStyle = document.getElementById('body').style;
    if (open) {
      bodyStyle.overflowY = 'hidden';
    } else {
      bodyStyle.overflowY = 'auto';
    }
  }, [open]);

  useEffect(() => {
    if (pathname === '/home') sidebarTools.close();
  }, [pathname]);

  return (
    <>
      <SidebarWrapper open={open}>
        {key && user && (
          <Link to="/conta" onClick={sidebarTools.close}>
            <UserInfoDiv>
              <UserIcon icon={faUserTie} />
              <UserName>{user.email}</UserName>
            </UserInfoDiv>
          </Link>
        )}
        {navItems.map((navItem, idx: number) => {
          return <SidebarItem {...navItem} key={idx} onClick={sidebarTools.close} />;
        })}
        {key ? (
          <SidebarButton onClick={logout}>Logout</SidebarButton>
        ) : (
          <SidebarItem name="Login/Registar" to="/login" onClick={sidebarTools.close} />
        )}
      </SidebarWrapper>
      <BlackDiv open={open} onClick={sidebarTools.close}></BlackDiv>
    </>
  );
};

export default Sidebar;
