import styled from 'styled-components';

const THead = styled.thead`
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: green;
`;

const TH = styled.th`
  padding: 15px 0;
  font-weight: 500;
  @media (max-width: 500px) {
    padding: 10px 0;
    font-size: 14px;
  }
  color: ${process.env.REACT_APP_2COLOR};
`;

const TD = styled.td`
  padding: 10px 0;
  font-weight: 500;
  @media (max-width: 500px) {
    padding: 7px 2px;
    font-size: 14px;
  }
`;

export { THead, TH, TD };
