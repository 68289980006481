import React, { useRef, useEffect } from 'react';
import HeaderLink from '../components/pieces/small/HeaderLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../images/logo-round2.png';
import { useLocation } from 'react-router-dom';
import useCart from '../hooks/useCart';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import Cart from '../components/pieces/large/Cart/Cart';
import {
  faShoppingBasket,
  faLock,
  faEnvelope,
  faUser,
  faSignOutAlt,
  faPhone,
  faBars,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Logo, InnerContainer, IconLink, IconButtons, HeaderContainer, TopInfoContainer } from './styledLayout';
import navItems from './navItems';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

const MobileCartIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
  margin: auto 10px auto 0;
  color: #f1f1f1;
`;

const MobileHeaderContainer = styled.div`
  top: 0;
  width: 100%;
  z-index: 101;
  height: 60px;
  padding: 10px;
  display: none;
  flex-direction: row;
  ${({ ishome }) =>
    ishome
      ? `background-color: #00000000;position: fixed;`
      : `
      background-image: ${process.env.REACT_APP_GRADIENT};
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
      @media (max-width: 1024px) {display: flex ;}`}
`;

const MobileCart = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: white;
`;

const MenuIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: white;
`;

const MobileHeaderTitle = styled.h2`
  margin: auto 0 auto 15px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 40px;
  color: white;
  letter-spacing: 1px;
`;

type HeaderProps = {
  toggleSidebar: () => void;
  logout: () => void;
};

const tabletSize: number = Number(process.env.REACT_APP_TABLET);

const Header: React.FC<HeaderProps> = ({ toggleSidebar, logout }) => {
  const mobileDivRef = useRef<HTMLDivElement>();
  const { pathname } = useLocation();
  const [cart, options] = useCart();
  const [cookie] = useCookies();

  const cartEmpty = options.totalItems === 0 ? true : false;

  const user = cookie['alflora-user'];

  window.addEventListener('scroll', () => {
    const isRefPrepared: boolean = mobileDivRef && mobileDivRef.current ? true : false;
    const isScrolled: boolean = window.scrollY > 0;
    if (isRefPrepared) {
      if (isScrolled) {
        mobileDivRef.current.style.position = 'fixed';
      } else {
        mobileDivRef.current.style.position = 'relative';
      }
    }
  });

  window.addEventListener('resize', () => {
    const isRefPrepared: boolean = mobileDivRef && mobileDivRef.current ? true : false;
    const isHome: boolean = pathname === '/home';
    if (isRefPrepared && isHome && window.outerWidth < tabletSize) {
      mobileDivRef.current.style.display = 'none';
    } else if (isRefPrepared && window.outerWidth < tabletSize) {
      mobileDivRef.current.style.display = 'flex';
    } else if (window.outerWidth >= tabletSize) {
      mobileDivRef.current.style.display = 'none';
    }
  });

  useEffect(() => {
    const isRefPrepared: boolean = mobileDivRef && mobileDivRef.current ? true : false;
    const isHome: boolean = pathname === '/home';
    if (isRefPrepared && isHome && window.outerWidth < tabletSize) {
      mobileDivRef.current.style.display = 'none';
    } else if (isRefPrepared && window.outerWidth < tabletSize) {
      mobileDivRef.current.style.display = 'flex';
    }
  }, [pathname]);

  return (
    <>
      <HeaderContainer>
        <div className="black-img"></div>
        <div className="flex flex-row mt-4">
          <Logo className="round" src={logo} />
          <div className="flex flex-col mx-auto">
            <TopInfoContainer>
              <span className="mx-4">
                <FontAwesomeIcon icon={faPhone} className="mr-1" />
                +351 229 965 820
              </span>
              <span className="mx-4">
                <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                info@alflora.com
              </span>
              {user && (
                <Link className="mx-4" to="/conta">
                  <FontAwesomeIcon icon={faUser} className="mr-1" /> {user.email}
                </Link>
              )}
              {!user ? (
                <Link className="mx-4" to="/login">
                  <FontAwesomeIcon icon={faLock} className="mr-1" />
                  Login
                </Link>
              ) : (
                <span className="mx-4 cursor-pointer" onClick={logout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
                  Logout
                </span>
              )}
            </TopInfoContainer>
            <InnerContainer>
              {navItems.map((item, idx) => (
                <HeaderLink {...item} alignRight={idx === 0 && true} key={idx} />
              ))}
              <IconLink href="https://www.facebook.com/alflora.pt/" target="_blank">
                <FontAwesomeIcon icon={faFacebookF} />
              </IconLink>
              <div className="relative">
                <IconButtons reverse={true} nopointer={cartEmpty} onClick={cartEmpty ? () => {} : options.toggleCart}>
                  <FontAwesomeIcon icon={faShoppingBasket} />
                </IconButtons>
                <Cart open={options.cartOpen} cart={cart} options={options} />
              </div>
            </InnerContainer>
          </div>
        </div>
      </HeaderContainer>
      <MobileHeaderContainer ref={mobileDivRef}>
        <MenuIcon icon={faBars} onClick={toggleSidebar} />
        <Link to="/home">
          <MobileHeaderTitle>Alflora</MobileHeaderTitle>
        </Link>
        <div className="ml-auto flex relative">
          <MobileCartIcon icon={faShoppingCart} onClick={cartEmpty ? () => {} : options.toggleCart} />
          <Cart open={options.cartOpen} cart={cart} options={options} mobile={true} />
        </div>
      </MobileHeaderContainer>
    </>
  );
};

export default React.memo(Header);
//<MobileCart icon={faShoppingBasket} />
