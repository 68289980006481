import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import getProduct from '../../../api/getProduct';
import Container from '../../pieces/small/Container';
import fallback from '../../../images/fallback.png';
import GoBack from '../../pieces/small/GoBack';
import NumberInput from '../../pieces/medium/NumberInput/NumberInput';
import useNumberInput from '../../../hooks/useNumberInput';
import { useCookies } from 'react-cookie';
import useCart from '../../../hooks/useCart';
import { ImageDiv, Image, Title, Price, Ref, Button } from './styled';
import useLoading from '../../../hooks/useLoading';
import LoadingSpinner from '../../pieces/small/LoadingSpinner';
import styled from 'styled-components';
import { Row } from '../../../styledMain';
import { FontAwesomeIcon } from '../Contactos/styled';
import Zoom from 'react-img-zoom';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import InnerImageZoom from 'react-inner-image-zoom';
import "./ProductDetail.css"

const ImageZoomBlackDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    svg {
      opacity: 1;
    }
    background: #13121275;
  }
`;

const FullScreenIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  transition: 0.3s ease;
  color: #f1f1f1;
  margin: auto;
  opacity: 0;
`;

const ImageModalDiv = styled.div`
  width: 60vw;
  height: 80vh;
  filter: drop-shadow(2px 4px 6px black);
  position: fixed;
  top: 10vh;
  left: 20vw;
  z-index: 30;
  background: white;
  display: flex;
  flex-direction: row;
`;

const FullScreenImage = styled.img`
  margin: auto 40px auto 0;
  max-height: 100%;
  border-right: 1px solid;
`;

const ProductDetailWrapper = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const StockWarningWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const InnerWrapper = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: 50%;
    ${({ side }) => {
      switch (side) {
        case 'left':
          return `padding-right: 1rem;`;
        case 'right':
          return `padding-left: 1rem;`;
        default:
          return '';
      }
    }}
  }
  ${({ css }) => css && `${css}`}
`;

type ProductTypes = {
  sku?: string | undefined;
  barcode?: string | undefined;
  name: string | undefined;
  description?: string | undefined;
  price?: number | undefined;
  stock?: number | undefined;
  image_main?: string | undefined;
  image_thumb?: string | undefined;
  id: number | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
};

const baseImgUrl: string = 'https://alflora.com/assets/img/ART';
const detailWrapperCss = `@media(max-width: 1024px){padding: ${process.env.REACT_APP_MOBILE_PADDINGY};}`;
const containerCss: string =
  '@media(max-width: 1024px){margin-top: 0; padding-top: 0;}@media(max-width: 768px){margin: 0rem 1rem;}@media(max-width: 768px){margin: 0rem;}';

const ProductDetail: React.FC = () => {
  const [product, setProduct] = useState<ProductTypes>(null);
  const [quantity, operator, onChange] = useNumberInput(1);
  const [realSrc, setRealSrc] = useState<string>('');
  const [loading, loadTools] = useLoading(true);
  const [, options] = useCart();
  const [cookie] = useCookies();
  const { id } = useParams();

  const API_KEY: string | undefined = cookie['alflora-key'];

  const setData = (product: ProductTypes) => {
    const src: string = `${baseImgUrl}/${product.image_main}`;
    setRealSrc(src);
    setProduct(product);
  };

  const buy = () => {
    options.add({ ...product, quantity }, true);
  };

  const handleFallback = () => {
    setRealSrc(fallback);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadTools.start();
    getProduct(cookie['alflora-key'], id, (data) => {
      setData(data);
      loadTools.loaded();
    });
  }, [id, cookie]);

  return API_KEY ? (
    <Container size="small" css={containerCss}>
      <div className="w-full">
        <StockWarningWrapper>
          <h3>Encomenda efetuada estará sujeita à disponibilidade de stocks</h3>
          <GoBack />
        </StockWarningWrapper>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <ProductDetailWrapper>
            <InnerWrapper side="left">
              <ImageDiv className="product-detail-img-div">
                
                <InnerImageZoom alt="product" src={realSrc} zoomSrc={realSrc} onError={handleFallback} />
              </ImageDiv>
            </InnerWrapper>
            <InnerWrapper side="right" css={detailWrapperCss}>
              <Title>{product && product.name}</Title>
              <Price>{product && product.price}€</Price>
              <Ref>
                REF: <span className="font-semibold">{product && product.sku}</span>
              </Ref>
              <Row css="flex-wrap: nowrap;@media(max-width: 500px){flex-wrap: wrap;}">
                <NumberInput value={quantity} onChange={onChange} operator={operator} isProductDetail={true} />
                <Button onClick={buy}>Adicionar á Encomenda</Button>
              </Row>
            </InnerWrapper>
          </ProductDetailWrapper>
        )}
      </div>
    </Container>
  ) : (
    <Redirect to="/login" />
  );
};

export default React.memo(ProductDetail);
// <ImageModalDiv>
//               <Zoom img={realSrc} height={350} width={500} zoomScale={3}/>
//             </ImageModalDiv>
//<ImageZoomBlackDiv>
//                  <FullScreenIcon icon={faExpandArrowsAlt} />
//                </ImageZoomBlackDiv>