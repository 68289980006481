const navItems: { name: string; to: string }[] = [
  { name: 'Home', to: '/home' },
  { name: 'Temas', to: '/themes' },
  { name: 'Produtos', to: '/produtos' },
  { name: 'Notícias', to: '/noticias' },
  { name: 'Empresa', to: '/empresa' },
  { name: 'Contacto', to: '/contacto' },
];

export default navItems;
