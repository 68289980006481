import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Empresa from '../components/pages/Empresa/Empresa';
import Contactos from '../components/pages/Contactos/Contactos';
import Login from '../components/pages/Login/Login';
import Products from '../components/pages/NewProducts';
import Home from '../components/pages/Home/Home';
import Posts from '../components/pages/Posts/Posts';
import Register from '../components/pages/Register/Register';
import PostDetail from '../components/pages/PostDetail/PostDetail';
import Checkout from '../components/pages/Checkout/Checkout';
import ProductDetail from '../components/pages/ProductDetail/ProductDetail';
import Account from '../components/pages/Account/Account';
import PasswordReset from '../components/pages/PasswordReset/PasswordReset';
import { useCookies } from 'react-cookie';
import usePreviousState from '../hooks/usePreviousState';
import { LogData } from './index';
import axios from 'axios';
import useCartContext from '../hooks/useCart';

type ContentProps = {
  setLogData: (val: LogData) => void;
};

const interceptor = (removeCookie) => {
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        removeCookie('alflora-user');
        removeCookie('alflora-key');
      }
    }
  );
};

const Content: React.FC<any> = ({ setLogData, logout }) => {
  const [cart] = useCartContext();
  const [previousState] = usePreviousState();
  const [, , removeCookie] = useCookies();

  interceptor(removeCookie);

  return (
    <div>
      <Switch>
        <Route exact path="/empresa" component={Empresa} />
        <Route exact path="/contacto" component={Contactos} />
        <Route exact path="/home" render={() => <Home logout={logout} />} />
        <Route exact path="/produtos" render={() => <Products previousState={previousState} />} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/noticias" component={Posts} />
        <Route exact path="/conta" component={Account} />
        <Route exact path="/noticias-detalhe/:id" component={PostDetail} />
        <Route exact path="/produto-detalhe/:id" component={ProductDetail} />
        <Route exact path="/password-reset" component={PasswordReset} />
        <Route exact path="/login" render={() => <Login setLogData={setLogData} />} />{' '}
        <Route exact path="/" render={() => <Redirect to="/home" />} />
      </Switch>
    </div>
  );
};

export default React.memo(Content);
