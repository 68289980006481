import React from 'react';
import { THead, TH, TD } from './styled';

type OrderDataProps = {
  orders?: any;
  current: string;
};

const getNormalDate = (date: string) => {
  const splited: string[] = date.split('-');
  const dateClean: string = [splited[0], splited[1], splited[2].split('T')[0]].join('/');
  return dateClean;
};

const OrderData: React.FC<OrderDataProps> = ({ orders, current }) => {
  return current === 'dados_encomendas' ? (
    <div>
      <table className="w-full mt-10">
        <THead>
          <tr>
            <TH className="text-center">ID</TH>
            <TH className="text-left">DATA</TH>
            <TH className="text-right">TOTAL</TH>
            <TH className="text-center">ESTADO DA ENCOMENDA</TH>
            <TH>AÇÕES</TH>
          </tr>
        </THead>
        <tbody>
          {orders &&
            orders.map((order) => {
              return (
                <tr>
                  <TD className="text-center">{order.id}</TD>
                  <TD className="text-left">{getNormalDate(order.createdAt)}</TD>
                  <TD className="text-right">{order.total.toFixed(2)}€</TD>
                  <TD className="text-center">{order.status}</TD>
                  <TD></TD>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  ) : null;
};

export default React.memo(OrderData);
