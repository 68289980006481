import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import fallback from '../../../../images/fallback.png';
import { Product } from '../../../pages/NewProducts/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CartDiv = styled.div`
  display: flex;
  height: 50px;
  margin: 5px 0;
  flex-direction: row;
`;

const Image = styled.img`
  width: 100%;
  height: 50px;
  object-fit: cover;
  border: 1px solid #ccc;
`;

const ProductText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #333;
  margin-top: 0;
  width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
  position: relative;
  top: 10px;
  color: #ccc;
  transition: 0.15s ease;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

const PriceP = styled.p`
  text-transform: none;
  color: #868686;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
`;

type CartCardPropTypes = {
  product: Product;
  del: (id: number) => void;
};

const baseImgUrl: string = 'https://alflora.com/assets/img/ART';

const CartCard: React.FC<CartCardPropTypes> = ({ product, del }) => {
  const [realSrc, setRealSrc] = useState<string>(`${baseImgUrl}/${product.image_main}`);

  const onError = () => {
    setRealSrc(fallback);
  };

  const deleteProduct = () => {
    del(product.id);
  };

  useEffect(() => {
    setRealSrc(`${baseImgUrl}/${product.image_main}`);
  }, [product]);

  return (
    <CartDiv>
      <div className="w-2/12 pr-2">
        <Image src={realSrc} alt="product" onError={onError} />
      </div>
      <div className="w-8/12 px-2">
        <ProductText>{product.name} </ProductText>
        <PriceP>
          {product.quantity} x {product.price}€
        </PriceP>
      </div>
      <div className="w-2/12 pl-2">
        <Icon icon={faTimes} onClick={deleteProduct} />
      </div>
    </CartDiv>
  );
};

export default React.memo(CartCard);
//<ProductText>{product.name} </ProductText> x{" "}
//<span className="text-black">{product.quantity}</span>
