import { useState } from 'react';

type Bind = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const useInput = (initialValue: string) => {
  const [value, setValue] = useState<string>(initialValue);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const reset: any = () => {
    setValue('');
  };

  const bind: Bind = {
    value,
    onChange,
  };

  return [value, bind, reset];
};

export default useInput;
