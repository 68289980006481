import styled from 'styled-components';

const Input = styled.input`
  padding: 10px 10px;
  width: 100%;
  display: block;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  transition: 0.2s linear;
  &::placeholder {
    color: #333;
  }
  &:focus {
    outline: none;
  }
  @media(max-width: 425px){
    padding: 7px;
    font-size: 14px;
  }
  ${({ extraCss }) => extraCss && extraCss}
  ${(props) =>
    props.wrong
      ? `background-color: #ff00003d; border: 1px solid red;`
      : 'background-color: #fafafa; border: 1px solid #333;'}
`;
export default Input;
