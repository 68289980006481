import React, { useEffect, useState } from "react";
import fallback from "../../../images/fallback.png";
import styled from "styled-components";
import { Product } from "../NewProducts/types";
import useNumberInput from "../../../hooks/useNumberInput";
import NumberInput from "../../pieces/medium/NumberInput/NumberInput";
import useCart from "../../../hooks/useCart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const SkuSpan = styled.span`
  color: #868686;
  font-size: 14px;
  font-weight: 600;
`;

const NameSpan = styled.span`
  display: block;
  font-weight: 600;
  font-family: arial;
`;

const Image = styled.img`
  width: 95px;
  height: 90px;
  object-fit: contain;
  margin: 6px auto 0 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  color: #5a5a5a;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 9.5px);
  @media (max-width: 1024px) {
    display: none;
  }
`;

type CheckoutRowPropTypes = {
  product: Product;
};

const TD = styled.td`
  min-height: 96px;
  position: relative;
  @media(max-width: 525px) ( 
    font-size: 14px;
  )
  ${({ css }) => css && css}
`;

const QuantitySpan = styled.span`
  display: none;
  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const baseImgUrl: string = "https://alflora.com/assets/img/ART";
const numberInputCss: string = `
margin: auto;
button { 
  padding: 6px 13px;
}
@media(max-width: 768px) { 
  display: none;
}
`;

const CheckoutRow: React.FC<CheckoutRowPropTypes> = ({ product: productFromProps }) => {
  const [, cartTools] = useCart();
  const [quantity, operator, onChange, setQuantity] = useNumberInput(productFromProps.quantity, (value) => {
    cartTools.add(Object.assign(productFromProps, { quantity: Number(value) }));
  });

  const onError = (e) => {
    e.target.src = fallback;
  };

  const deleteProduct = (id: number) => {
    cartTools.del(id);
  };

  useEffect(() => {
    setQuantity(productFromProps.quantity);
  }, [productFromProps]);

  return (
    <tr className="">
      <td className="text-center">
        <Image src={`${baseImgUrl}/${productFromProps.image_main}`} onError={onError} />
      </td>
      <TD className="text-left" colSpan={2}>
        <NameSpan>
          {productFromProps.name}
          <QuantitySpan>x {quantity}</QuantitySpan>
        </NameSpan>
        <SkuSpan>{productFromProps.sku}</SkuSpan>
      </TD>
      <TD className="text-right">{productFromProps.price.toFixed(2)}€</TD>
      <TD className="text-center flex flex-row">
        <NumberInput value={quantity} onChange={onChange} operator={operator} css={numberInputCss} isProductDetail={false} />
      </TD>
      <TD className="text-right font-semibold" css="min-width: 70px;">
        {parseFloat(String(productFromProps.total)).toFixed(2)}€
      </TD>
      <TD css="position: relative !important;">
        <DeleteIcon icon={faTimes} onClick={() => deleteProduct(productFromProps.id)} />
      </TD>
    </tr>
  );
};

export default CheckoutRow;
//      <SkuTd className="text-center px-4">{product.sku}</SkuTd>
