import React from 'react';
import Layout from './layout';
import { HashRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import './App.css';
import CartProvider from './layout/CartProvider';

function App() {

  window.onpopstate = (e) => {
    window.scrollTo(0, 0);
  };

  return (
    <CartProvider>
      <CookiesProvider>
        <HashRouter>
          <Layout />
        </HashRouter>
      </CookiesProvider>
    </CartProvider>
  );
}

export default App;
