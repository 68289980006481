import { removeFromArray } from './../utils';
import { useState } from 'react';
import useModal from './useModal';

const getPercentageInput = (input: string) => {
  if (input !== '') {
    const inputArray = input.split(' ');
    const inputWithPercent = inputArray.join('%');
    return inputWithPercent;
  }
  return '';
};

interface CleanFilterPayload {
  nameInput: string;
  skuInput: string;
  currentCategories: string[];
}

const getCleanFilters = (object: CleanFilterPayload, setCurrentCategories) => {
  const { nameInput, skuInput, currentCategories } = object;
  const nameInputWithPercent = getPercentageInput(nameInput);

  let payload = {
    sku: [],
    name: nameInputWithPercent,
  };

  if (nameInput !== '' || currentCategories.length !== 0 || skuInput !== '') {
    if (skuInput.length > 0) {
      payload.sku = [`${skuInput}%`];
      setCurrentCategories([]);
    } else if (currentCategories.length > 0) {
      currentCategories.forEach((external_id: string) => {
        payload.sku.push(`${external_id}%`);
      });
    } else delete payload.sku;

    if (nameInput === '') delete payload.name;

    return payload;
  } else {
    return null;
  }
};

interface initialObject {
  currentCategories: string[];
  nameInput: string;
  skuInput: string;
  actualFilters: any;
}

const defaultInitialObject: initialObject = {
  currentCategories: [],
  nameInput: '',
  skuInput: '',
  actualFilters: null,
};

const useFilter = (initialObject: initialObject = defaultInitialObject) => {
  const [currentCategories, setCurrentCategories] = useState<string[]>(initialObject.currentCategories);
  const [nameInput, setNameInput] = useState<string>(initialObject.nameInput);
  const [skuInput, setSkuInput] = useState<string>(initialObject.skuInput);
  //FILTERTOSEND
  const [actualFilters, setActualFilters] = useState(initialObject.actualFilters);
  //EXTRAS
  const [filterOpen, filterOpenTools] = useModal();
  const isEmpty = currentCategories.length === 0 && nameInput === '' && skuInput === '';

  const retrieve = (obj) => {
    setCurrentCategories(obj.currentCategories);
    setNameInput(obj.nameInput);
    setSkuInput(obj.skuInput);
    setActualFilters(obj.actualFilters);
  };

  const reset = (cb = () => {}) => {
    setCurrentCategories([]);
    setNameInput('');
    setSkuInput('');
    setActualFilters(null);
    cb();
  };

  const apply = (cb = () => {}) => {
    const payloadToApply = getCleanFilters({ currentCategories, nameInput, skuInput }, setCurrentCategories);
    cb();
    setActualFilters(payloadToApply);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const code = e.keyCode;
    if (code === 13) {
      apply();
    }
  };

  const toggleCategory = (externalId: string) => {
    const exists = currentCategories.includes(externalId);
    if (exists) {
      const newCurrent = removeFromArray(externalId, currentCategories);
      setCurrentCategories(newCurrent);
    } else {
      setCurrentCategories([...currentCategories, externalId]);
    }
  };

  //INPUT BINDING
  const bind = {
    name: {
      value: nameInput,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setNameInput(e.target.value),
      onKeyDown: handleInputKeyDown,
    },
    sku: {
      value: skuInput,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSkuInput(e.target.value),
      onKeyDown: handleInputKeyDown,
    },
    filterItems: (external_id: string) => {
      return { onClick: () => toggleCategory(external_id), active: currentCategories.includes(external_id) };
    },
  };

  const filterTools = {
    reset,
    apply,
    isEmpty,
    filterOpen,
    retrieve,
    allVals: {
      currentCategories,
      nameInput,
      skuInput,
      actualFilters,
    },
    modal: filterOpenTools,
    mobileApply: (cb = () => {}) => {
      filterOpenTools.close();
      apply(cb);
    },
    mobileReset: (cb = () => {}) => {
      filterOpenTools.close();
      reset(cb);
    },
  };

  return { actualFilters, bind, filterTools };
};

export default useFilter;
