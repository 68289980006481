import { useState } from 'react';
import useLoading from './useLoading';

export interface ModalTools {
  open: VoidFunction;
  close: VoidFunction;
  toggle: VoidFunction;
  load: {
    start: VoidFunction;
    finish: VoidFunction;
    modalLoading: boolean
  }
}

export default function useModal(): [boolean, ModalTools] {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalLoading, modalLoadTools] = useLoading(false);

  const tools: ModalTools = {
    open: () => setModalOpen(true),
    close: () => setModalOpen(false),
    toggle: () => setModalOpen(!modalOpen),
    load: {
      start: modalLoadTools.start,
      finish: modalLoadTools.finish,
      modalLoading
    },
  };

  return [modalOpen, tools];
}
