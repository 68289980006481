import { CountryObject } from './countries';
import axios, { AxiosResponse } from "axios";

export interface Region {
  abbreviation: string;
  country: number;
  createdAt: string;
  id: number;
  name: string;
  postalCodeFrom: number;
  postalCodeUntil: number;
  shippingZone: number;
  unCode: string | number;
  updatedAt: string;
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  b2c_user?: boolean;
  city: string;
  country: number | CountryObject;
  createdAt?: string;
  enabled?: boolean;
  entity?: number;
  external_id?: string;
  id?: number;
  name: string;
  postalCode: string;
  region: number | Region;
  updatedAt?: string;
}

export interface User {
  country: any;
  region: number;
  entity?: {
    name: string;
    customerCode: string;
    supplierCode: string;
    branchCode: string;
    vatNr: string;
    price_table: number;
    isActive: true;
    id: number;
    createdAt: string;
    updatedAt: string;
    billingAddress: number | Address;
    deliveryAddress: number | Address;
  };
  email: string;
  image_main: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  phonePrivate: string;
  mobilePrivate: string;
  vatNumber: string;
  isAdmin: boolean;
  isActiveUser: boolean;
  activationNotes: string;
  external_id: string;
  id: number;
  createdAt: string;
  updatedAt: string;
}

const postUser = async (
  payload,
  onSuccess = (res) => {},
  onError = (res) => {}
) => {
  const url: string | undefined = process.env.REACT_APP_URL;
  const fullUrl: string = `${url}/users`;

  try {
    const res: AxiosResponse = await axios.post(fullUrl, payload);
    onSuccess(res.data);
  } catch (error) {
    onError(error);
  }
};

export default postUser;
