import React, { useState } from 'react';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import './Layout.css';
import { useHistory } from 'react-router-dom';
import useCart from '../hooks/useCart';
import { useCookies } from 'react-cookie';


const Site = styled.div`
  min-height: 70vw;
  position: relative;
`;

export type LogData = {
  entity: number | null;
  email: string | null;
  image_main: string | null;
  name: string | null;
  isAdmin: boolean;
  isActiveUser: boolean;
  activationNotes: any;
  external_id: null | number;
  id: number;
  createdAt: string | null;
  updatedAt: string | null;
};

export interface SidebarTools {
  open: () => void;
  toggle: () => void;
  close: () => void;
}

const useSidebar = (initialBool: boolean = false) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(initialBool);

  const sidebarTools: SidebarTools = {
    open: () => setSidebarOpen(true),
    toggle: () => setSidebarOpen(!sidebarOpen),
    close: () => setSidebarOpen(false),
  };

  return { sidebarOpen, sidebarTools };
};

const Layout: React.FC = () => {
  const [logData, setLogData] = useState<LogData | null>(null);
  const { sidebarOpen, sidebarTools } = useSidebar(false);
  const [cookie, , removeCookie] = useCookies();
  const [cart, options] = useCart();
  const history = useHistory();

  const logout = () => {
    removeCookie('alflora-key');
    removeCookie('alflora-user');
    options.reset();
    history.push('/home');
  };

  return (
    <Site>
      <Header toggleSidebar={sidebarTools.toggle} logout={logout} />
      <Sidebar sidebarTools={sidebarTools} open={sidebarOpen} logout={logout} />
      <Content setLogData={setLogData} logout={logout}/>
      <Footer />
    </Site>
  );
};

export default Layout;
