import styled from 'styled-components';

const PaginationWrapper = styled.div`
  margin-left: auto;
  width: fit-content;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
const SeeMoreButton = styled.button`
  border-radius: 3px;
  padding: 10px 5px;
  text-align: center;
  width: 100%;
  letter-spacing: 0.5px;
  color: white;
  text-transform: uppercase;
  font-weight: bolder;
  background-image: ${process.env.REACT_APP_GRADIENT};
  margin-bottom: 10px;
  &:focus {
    outline: black;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;
//border: 1px solid ${process.env.REACT_APP_2COLOR};
const PageButton = styled.button`
  padding: 8px 14px;
  margin: auto 4px;
  border-radius: 6px;
  transition: 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  background: #f1f1f1;
  &:hover {
    background-color: ${process.env.REACT_APP_2COLOR};
    color: white;
  }
  &:focus {
    outline: none;
  }
  ${(props) => props.active === true && `background-color: ${process.env.REACT_APP_2COLOR}; color: white`}
  ${(props) => props.notNumber && `font-weight: 400; font-size: 16px; padding: auto;`}
`;

export { PaginationWrapper, PageButton, SeeMoreButton };
