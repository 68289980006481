import React from 'react';
import { Button, Arrow } from './styled';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const SortButton = ({ onClick, status, texts, className = '' }) => {
  const textToShow = status ? texts[status] : texts['DOWN'];

  return (
    <Button onClick={onClick} className={className}>
      {textToShow} <Arrow icon={faChevronDown} status={status} />{' '}
    </Button>
  );
};

export default React.memo(SortButton);
