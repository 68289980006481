import styled from 'styled-components';

const Box = styled.div`
  border: 1px solid #ccc;
  margin: 2rem auto;
  padding: 2rem 5rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  transition: 0.2s linear;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: 60%;
  }
  ${({ css }) => css && `${css}`}
`;

export const Text = styled.p`
  color: #666;
  font-size: 16px;
  @media(max-width: 500px){
    font-size: 14px;
    text-align: justify;
  }
`;
const BoxTitle = styled.h2`
  font-weight: 500;
  letter-spacing: 1.2px;
`;

const BoxText = styled.p`
  font-size: 21px;
  padding: 40px 0;
`;

const TH2 = styled.th`
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
`;
const TD2 = styled.td`
  padding: 18px 10px;
`;
const TBody2 = styled.tbody`
  border-bottom: 1px solid #ccc;
`;
const Total = styled.span`
  font-weight: 600;
  text-transform: uppercase;
`;
const TR2 = styled.tr``;
const THead2 = styled.thead`
  border-bottom: 1px solid #ccc;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ css }) => css && `${css}`}
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${({ css }) => css && `${css}`}
`;
export { Box, Row, Column, BoxTitle, BoxText, TH2, TD2, TBody2, Total, TR2, THead2 };
