import styled from "styled-components";

const Button = styled.button`
  transition: all 0.4s ease 0s;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 1.5rem auto;
  border-radius: 2px;
  padding: 10px 7px;
  font-weight: 600;
  color: #f1f1f1;
  width: 100%;
  &:focus {
    outline: none;
  }
  ${({ extraCss }) => extraCss && extraCss}
  ${({ disabled }) => (disabled ? "background: #909090;cursor: initial;" : "background: #3a695a;")}
`;

export default Button;
