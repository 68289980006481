import React, { useState, useEffect, useReducer } from "react";
import ProductsProps, { Product } from "./types";
import getProductCategories, { ProductCategories } from "../../../api/getProductCategories";
import { useCookies } from "react-cookie";
import getProducts from "../../../api/getProducts";
import useLoading from "../../../hooks/useLoading";
import usePagination from "../../../hooks/usePagination";
import { isB2B } from "../../../utils";
import Filter from "../../pieces/large/Filter/Filter";
import Pagination from "../../pieces/medium/Pagination/Pagination";
import { Redirect } from "react-router-dom";
import ProductCard from "../../pieces/medium/ProductCard/ProductCard";
import LoadingSpinner from "../../pieces/small/LoadingSpinner";
import { BoxText, Box, Column } from "../../../styledMain";
import { Title } from "../Contactos/styled";
import useFilter from "../../../hooks/useFilter";
import MobileLoading from "./MobileLoading";
import styled from "styled-components";
import _ from "lodash";
import useSort from "../../../hooks/useSort";
import getLastProducts from "../../../api/getLastProducts";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProductsFullWrapper, ProductWrapper, FilterWrapper, UpperWrapper, UpperPaginationWrapper, StockWarning, ProductAndFilterWrapper, NoProducts } from "./styled";
import SortButton from "../../pieces/medium/SortButton";

const SortHeader = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e5e5e5;
  margin-top: calc(1rem + 7.5px);
  margin-bottom: 0.5rem;
  padding-bottom: 8px;
  margin-right: 1rem;
  margin-left: 1rem;
  @media (max-width: 1024px) {
    margin-top: 10px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
`;

const tabletSize: number = Number(process.env.REACT_APP_TABLET);
const textsInSortCreated = { UP: "ANTIGOS", DOWN: "RECENTES" };

const Presentational = ({ products, productCategories, loading, getMoreProducts, filterTools, pageTools, bind, changeSort, checkSortStatus }) => {
  const [cookie] = useCookies();

  const API_KEY: string = cookie["alflora-key"];
  const readyToShowProducts: boolean = cookie["alflora-user"] && isB2B(cookie["alflora-user"].entity) ? true : false;
  const hasMore: boolean = pageTools.pageInfo ? (pageTools.pageInfo.actualPage <= pageTools.pageInfo.totalPages ? true : false) : true;

  return (
    <>
      {readyToShowProducts && <Filter categories={productCategories} filterTools={filterTools} bind={bind} resetPage={pageTools.resetPage} type="phone" />}
      {API_KEY ? (
        <ProductsFullWrapper>
          {readyToShowProducts ? (
            <>
              <UpperWrapper>
                <Column>
                  <StockWarning>Encomenda efetuada estará sujeita á disponibilidade de stocks</StockWarning>
                  <StockWarning>Preço sem IVA à taxa legal em vigor</StockWarning>
                </Column>
                {!loading && (
                  <UpperPaginationWrapper>
                    <Pagination pageInfo={pageTools.pageInfo} onClick={pageTools.selectPage} empty={products.length === 0} />
                  </UpperPaginationWrapper>
                )}
              </UpperWrapper>
              <ProductAndFilterWrapper>
                <FilterWrapper>
                  <Filter categories={productCategories} bind={bind} filterTools={filterTools} resetPage={pageTools.resetPage} />
                </FilterWrapper>
                <ProductWrapper>
                  <SortHeader>
                    <SortButton className="ml-auto" status={checkSortStatus("createdAt")} texts={textsInSortCreated} onClick={() => changeSort("createdAt", pageTools.resetPage)} />
                  </SortHeader>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {products.length === 0 && <NoProducts>Não existem produtos que correspondam aos critérios de pesquisa indicados!</NoProducts>}
                      <InfiniteScroll style={{ display: "flex", flexWrap: "wrap" }} className="infinite-scroll-gallery" dataLength={products.length} next={getMoreProducts} hasMore={hasMore} loader={<MobileLoading />}>
                        {products.length > 0 ? products.map((product: Product, idx: number) => <ProductCard product={product} key={idx} />) : null}
                      </InfiniteScroll>
                      <Pagination pageInfo={pageTools.pageInfo} onClick={pageTools.selectPage} empty={products.length === 0} />
                    </>
                  )}
                </ProductWrapper>
              </ProductAndFilterWrapper>
            </>
          ) : (
            <Box>
              <Title className="text-center mb-8">Conta em Verificação</Title>
              <BoxText>A sua conta está a ser verificada pelos nossos peritos. Até tal processo estar terminado, a vizualização dos nossos produtos encontra-se restricta.</BoxText>
            </Box>
          )}
        </ProductsFullWrapper>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

const PresentationMemo = React.memo(Presentational);

const filterDefault = { currentCategories: [], nameInput: "", skuInput: "", actualFilters: null };
const pageDefault = { actualPage: 1, lastPage: 1 };
const currentSortDefault: string = "createdAt DESC";

const reducer = (products: Product[], action) => {
  switch (action.type) {
    case "add":
      return products.concat(action.payload);
    case "set":
      return action.payload;
    case "reset":
      return [];
  }
};

const Container: React.FC<ProductsProps> = ({ previousState }) => {
  const stateParams = previousState.getState("params");
  const isParamsEmpty: boolean = !stateParams && _.isEmpty(stateParams) ? true : false;

  const sortToInsert: string = isParamsEmpty ? currentSortDefault : stateParams.currentSort;
  const pageToInsert = isParamsEmpty ? pageDefault : stateParams.page;
  const filtersToInsert = isParamsEmpty ? filterDefault : stateParams.filterVals;
  //ACTUAL STATE
  const [products, dispatch] = useReducer(reducer, []);
  const [productCategories, setProductCategories] = useState<ProductCategories[] | null>(null);
  const { currentSort, changeSort, checkSortStatus } = useSort(sortToInsert);
  //FILTER
  const { actualPage, pageTools } = usePagination(pageToInsert);
  const { actualFilters, bind, filterTools } = useFilter(filtersToInsert);
  //EXTRA
  const [loading, loadTools] = useLoading(true);
  const [cookie] = useCookies();

  const getMoreProducts = () => {
    if (window.outerWidth <= 1024 && pageTools.pageInfo.actualPage <= pageTools.pageInfo.totalPages) pageTools.directSelect(actualPage + 1, actualPage);
  };

  const replaceHistory = () => {
    let paramsToReplaceState = {
      page: { actualPage, lastPage: actualPage },
      filterVals: { ...filterTools.allVals },
      currentSort,
    };
    window.history.replaceState({ params: paramsToReplaceState }, "products");
  };

  useEffect(() => {
    getProductCategories(cookie["alflora-key"], setProductCategories);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const isMobile: boolean = window.outerWidth <= tabletSize;
    const hasChangedPage: boolean = pageTools.lastPage !== actualPage;
    const isToGetFullList: boolean = isMobile && actualPage > 1 && !hasChangedPage;

    if (!isMobile) {
      window.scrollTo(0, 0);
      loadTools.start();
    } else if (!hasChangedPage) {
      loadTools.start();
    }

    if (isToGetFullList) {
      getLastProducts(cookie["alflora-key"], actualPage, currentSort, actualFilters, (data) => {
        dispatch({ type: "set", payload: data.product });

        replaceHistory();
        pageTools.getPageInfo(data);
        loadTools.loaded();
      });
    } else {
      getProducts(cookie["alflora-key"], actualPage, currentSort, actualFilters, (data) => {
        if (isMobile && products && hasChangedPage) {
          dispatch({ type: "add", payload: data.product });
        } else dispatch({ type: "set", payload: data.product });

        replaceHistory();
        pageTools.getPageInfo(data);
        loadTools.loaded();
      });
    }
  }, [actualFilters, actualPage, currentSort]);

  return (
    <PresentationMemo
      //STATE FETCHED
      productCategories={productCategories}
      getMoreProducts={getMoreProducts}
      products={products}
      //TOOLS
      filterTools={filterTools}
      pageTools={pageTools}
      changeSort={changeSort}
      checkSortStatus={checkSortStatus}
      //EXTRA
      loading={loading}
      bind={bind}
    />
  );
};

export default Container;

//const getPrevInfo = () => {
//  const params = previousState.getState('params');
//  const isParamsEmpty: boolean = _.isEmpty(params);
//
//  let paramsToUse = { actualPage, actualFilters, currentSort };
//
//  if (!isParamsEmpty) {
//    pageTools.set(params.page);
//    filterTools.retrieve(params.filterVals);
//    sortTools.directSelect(params.currentSort);
//    paramsToUse = {
//      actualFilters: params.filterVals.actualFilters,
//      actualPage: params.page.actualPage,
//      currentSort: params.currentSort,
//    };
//  }
//
//  return { paramsToUse, hasHistory: params && !isParamsEmpty ? true : false };
//};
