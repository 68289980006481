import React from 'react';
import styled from 'styled-components';

const MobileLoadWrapper = styled.div`
  position: fixed;
  bottom: 5vh;
  display: none;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const MobileLoading = () => {
  return (
    <MobileLoadWrapper>
      <div className="lds-ring mx-auto my-auto">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </MobileLoadWrapper>
  );
};

export default React.memo(MobileLoading);
