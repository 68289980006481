import React, { useState, useRef, useEffect } from 'react';
import Input from '../../../pieces/small/Input';
import Button from '../../../pieces/small/Button';
import { validateEmail } from '../../../../utils';
import axios from 'axios';
import { ModalTitle, ContentWrapper, Modal, BlackDiv, SuccessMessage } from './styled';

const extraCss = `padding: 6px 10px !important;`;
const buttonExtraCss = `padding: 8px 10px !important;margin: 20px 0 0 0;`;

const recoverPass = async (email, cb1 = (data) => {}, cb2 = (err) => {}) => {
  const baseUrl = process.env.REACT_APP_URL;
  const fullUrl = `${baseUrl}/auth/reset`;

  try {
    const res = await axios.post(fullUrl, { email });
    cb1(res.data);
  } catch (err) {
    cb2(err);
  }
};

interface PassModalPropTypes {
  open: boolean;
  close: () => void;
}

const PassModal: React.FC<PassModalPropTypes> = ({ open, close }) => {
  const [email, setEmail] = useState<string>('');
  const [invalid, setInvalid] = useState<boolean>(false);
  const [messageSend, setMessageSend] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSend = () => {
    const isRefPrepared: boolean = inputRef && inputRef.current ? true : false;
    const valid = validateEmail(email);
    if (valid) {
      recoverPass(email, () => {
        setMessageSend(true);
        setTimeout(() => {
          close();
        }, 2500);
      });
    } else if (isRefPrepared) {
      inputRef.current.focus();
      setInvalid(true);
    }
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isReturn: boolean = e.keyCode === 13;
    if (isReturn) handleSend();
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvalid(false);
    setEmail(e.target.value);
  };

  useEffect(() => {
    const isRefPrepared: boolean = inputRef && inputRef.current ? true : false;
    if (isRefPrepared) {
      inputRef.current.focus();
    }
    return () => {
      setInvalid(false);
      setMessageSend(false);
    };
  }, [open]);

  return (
    open && (
      <>
        <BlackDiv onClick={close}></BlackDiv>
        <Modal>
          <ModalTitle>{messageSend ? 'Pedido de Recuperação Enviado' : 'Recuperação de palavra-passe'}</ModalTitle>
          {!messageSend ? (
            <ContentWrapper>
              <p>
                Por favor, indique o email associado a sua conta. Receberá na sua caixa de correio um email com os dados de alteração de
                password.
              </p>
              <Input
                placeholder="Email Associado"
                onChange={handleInput}
                extraCss={extraCss}
                wrong={invalid}
                ref={inputRef}
                value={email}
                onKeyDown={onInputKeyDown}
              />
              <div className="flex flex-row mt-auto">
                <Button extraCss={buttonExtraCss} onClick={handleSend}>
                  Enviar Pedido de Recuperação
                </Button>
              </div>
            </ContentWrapper>
          ) : (
            <SuccessMessage>Foi enviado uma mensagem de recuperação de password para o email: {email}</SuccessMessage>
          )}
        </Modal>
      </>
    )
  );
};

export default React.memo(PassModal);
