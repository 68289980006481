import React, { useState, useEffect } from 'react';
import Container from '../../pieces/small/Container';
import getPosts from '../../../api/getPosts';
import PostCard from '../../pieces/medium/PostCard';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useLoading from '../../../hooks/useLoading';
import LoadingSpinner from '../../pieces/small/LoadingSpinner';

export type PostsData = {
  heading: string | undefined;
  body: string | undefined;
  groupName: string | undefined;
  publishFrom: string | undefined;
  publishUntil: string | undefined;
  id: number;
  createdAt: string | undefined;
  updatedAt: string | undefined;
};

const postsContainer: string = `
  @media(max-width: 1024px){
     padding: 15px; 
     margin: 0 0 0 0;
  } 
  @media(max-width: 425px){
     padding: 0 !important;
     margin: 10px 0 !important;
  } 
`;

const Posts: React.FC = () => {
  const [posts, setPosts] = useState<PostsData[] | null>(null);
  const [loading, loadTools] = useLoading(true);
  const [cookie] = useCookies();

  const API_KEY: string | undefined = cookie['alflora-key'];

  useEffect(() => {
    loadTools.start();
    getPosts(cookie['alflora-key'], 1, (posts: PostsData[]) => {
      setPosts(posts);
      loadTools.loaded();
    });
  }, [cookie]);

  return API_KEY ? (
    <Container css={postsContainer}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex flex-row flex-wrap">{posts ? posts.map((post: PostsData) => <PostCard post={post} />) : null}</div>
      )}
    </Container>
  ) : (
    <Redirect to="/login" />
  );
};

export default React.memo(Posts);
