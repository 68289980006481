import React, { useEffect, useState } from 'react';
import img1 from '../../../images/welcom-frame-img-3.jpg';
import img2 from '../../../images/welcom-frame-img-1.jpg';
import getPosts from '../../../api/getPosts';
import PostCard from '../../pieces/medium/PostCard';
import { useCookies } from 'react-cookie';
import homeBackground from '../../../images/banner-img-2.jpg';
import navItems from '../../../layout/navItems';
import {
  HomeMobileTitle,
  HomeMobileWrapper,
  ImageBackground,
  HomeMobileAnchor,
  HomeMobileButton,
  ImageDarkWrapper,
  MobileHomeSection,
  TitleGreen,
  HomeSection,
} from './styled';
import './Home.css';

interface HomePropTypes {
  logout: () => void;
}

const Home: React.FC<HomePropTypes> = ({ logout }) => {
  const [post, setPost] = useState(null);
  const [cookie] = useCookies();

  const setOnePost = (posts) => {
    setPost(posts[0]);
  };

  useEffect(() => {
    getPosts(cookie['alflora-key'], 1, setOnePost);
  }, [cookie]);

  return (
    <main>
      <HomeSection>
        <div className="p-10 flex flex-row w-full">
          <div className="w-5/12 welcome">
            <TitleGreen>Bem-Vindo</TitleGreen>
            <h2 className="my-0">Decoração & Sector Floral</h2>
            <h3 className="mt-0">Distribuição Grossista</h3>
            <h3>Apenas para Empresas | Profissionais</h3>
          </div>
          <div className="w-7/12 home-imgs p-6 relative">
            <div className="gallery">
              <div className="black-1"></div>
              <img src={img1} alt="about-us-1" className="img-1" />
              <div className="black-2"></div>
              <img src={img2} alt="about-us-2" className="img-2" />
            </div>
          </div>
        </div>
      </HomeSection>
      <MobileHomeSection>
        <HomeMobileWrapper>
          <HomeMobileTitle>Alflora</HomeMobileTitle>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/4">
              {navItems.map((navItem) => {
                return <HomeMobileAnchor to={navItem.to}>{navItem.name}</HomeMobileAnchor>;
              })}
              {cookie['alflora-key'] ? (
                <HomeMobileButton onClick={logout}>Logout</HomeMobileButton>
              ) : (
                <HomeMobileAnchor to="/login">Login/Registar</HomeMobileAnchor>
              )}
            </div>
          </div>
        </HomeMobileWrapper>
        <ImageDarkWrapper> </ImageDarkWrapper>
        <ImageBackground src={homeBackground} />
      </MobileHomeSection>
      <section className="lg:px-10 py-10">
        <div className="text-center mb-10">
          <TitleGreen>As nossas Notícias</TitleGreen>
        </div>
        <div className="lg:mx-24">{post && <PostCard post={post} css="width: 100% !important" />}</div>
      </section>
    </main>
  );
};

export default React.memo(Home);
