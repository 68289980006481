import React, { useState } from "react";
import { Product } from "../NewProducts/types";
import fallback from "../../../images/fallback.png";
import { SkuSpan, NameSpan, Image, TD, QuantitySpan, QuantitySpan2 } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type CheckoutRowPropTypes = {
  product: Product;
};

const baseImgUrl: string = "https://alflora.com/assets/img/ART";

const CheckoutRow: React.FC<CheckoutRowPropTypes> = ({ product }) => {
  const [realSrc, setRealSrc] = useState<string>(`${baseImgUrl}/${product.image_main}`);

  const onError = () => {
    setRealSrc(fallback);
  };

  return (
    <tr className="">
      <td className="text-center">
        <Image src={realSrc} onError={onError} />
      </td>
      <TD className="text-left" colSpan={2}>
        <NameSpan>
          {product.name}
          <QuantitySpan>x {product.quantity}</QuantitySpan>
        </NameSpan>
        <SkuSpan>{product.sku}</SkuSpan>
      </TD>
      <TD className="text-right">{product.price.toFixed(2)}€</TD>
      <TD className="text-center">
        <QuantitySpan2>{product.quantity}</QuantitySpan2>
      </TD>
      <TD className="text-right font-semibold" css="min-width: 60px;">
        {parseFloat(String(product.total)).toFixed(2)}€
      </TD>
    </tr>
  );
};

export default React.memo(CheckoutRow);
//      <SkuTd className="text-center px-4">{product.sku}</SkuTd>
