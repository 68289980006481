import axios from 'axios';

const alterPassword = async (id, payload, cb1, cb2) => {
  const baseUrl = process.env.REACT_APP_URL;
  const fullUrl = `${baseUrl}/users/${id}/password`;

  const config = {
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    await axios.post(fullUrl, payload, config);
    cb1();
  } catch (err) {
    cb2();
  }
};

export default alterPassword;
