import React, { useState, useRef, useEffect } from 'react';
import Container from '../../pieces/small/Container';
import { useHistory } from 'react-router-dom';
import { Box, BoxTitle } from '../../../styledMain';
import Input from '../../pieces/small/Input';
import Button from '../../pieces/small/Button';
import validateToken from '../../../api/validateToken';
import alterPassword from '../../../api/alterPassword';
import { SuccessMsg } from './styled';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PasswordReset: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(null);
  const [passWrong, setPassWrong] = useState<boolean>(false);
  const passRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const query = useQuery();

  const handlePass = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPass = (e: React.ChangeEvent<HTMLInputElement>) => {
    const readyToCheck: boolean = e.target.value !== password && password.length > 0;
    if (readyToCheck) {
      setPassWrong(true);
    } else setPassWrong(false);
    setConfirmPassword(e.target.value);
  };

  const save = () => {
    const token = query.get('token');
    alterPassword(
      userId,
      {
        token,
        password,
        passwordConfirmation: confirmPassword,
      },
      () => {
        setSuccess(true);
        setTimeout(() => {
          history.push('/login');
        }, 2000);
      },
      () => {
        setSuccess(false);
      }
    );
  };

  useEffect(() => {
    const token = query.get('token');
    if (passRef && passRef.current && validated) {
      passRef.current.focus();
    }
    if (token) {
      validateToken(
        token,
        (data) => {
          setValidated(true);
          setUserId(data.user.id);
        },
        () => {
          setValidated(false);
        }
      );
    } else {
      history.push('/home');
    }
  }, []);

  return (
      <Container>
        <Box>
          <BoxTitle className="text-center mb-8">Alteração de Palavra-passe</BoxTitle>
          {!success ? (
            <div className="flex flex-col">
              <Input placeholder="Password" type="password" ref={passRef} value={password} onChange={handlePass} />
              <Input
                placeholder="Confirmar Password"
                type="password"
                wrong={passWrong}
                value={confirmPassword}
                onChange={handleConfirmPass}
              />
              <Button onClick={save}>Confirmar</Button>
            </div>
          ) : (
            <SuccessMsg className="py-10">Palavra-Passe alterada com sucesso!</SuccessMsg>
          )}
        </Box>
      </Container>
  );
};

export default React.memo(PasswordReset);
