import axios, { AxiosResponse } from "axios";

export type ProductCategories = {
    parent: null | number;
    name: string;
    description: null | string;
    image_main: null | string;
    external_id: string;
    id: number;
    createdAt: string | null;
    updatedAt: string | null;
  };

const getProductCategories = async (key, setData) => {
  const url: string | undefined = process.env.REACT_APP_URL;
  const fullUrl: string = `${url}/productcategories`;

  const config = {
    params: { where: { parent: null } },
    headers: {
      Authorization: `Bearer ${key}`,
    },
  };

  try {
    const res: AxiosResponse = await axios.get(fullUrl, config);
    setData(res.data.productCategories);
  } catch (err) {
    console.log(err);
  }
};

export default getProductCategories;
