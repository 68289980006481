import axios, { AxiosResponse } from "axios";

const getPosts = async (key: string, actualPage: number, cb1 = (data) => {}, cb2 = (err) => {}) => {
  const fullUrl: string = `${process.env.REACT_APP_URL}/contents`;

  const config = {
    headers: {
      Authorization: `Bearer ${key}`,
    },
    params: {
      page: actualPage,
      limit: 10,
      sort: "createdAt DESC",
      where: {
        group: { name: { startsWith: "Noticias" } },
      },
    },
  };

  try {
    const res: AxiosResponse = await axios.get(fullUrl, config);
    cb1(res.data.contents);
  } catch (err) {
    cb2(err);
  }
};

export default getPosts;
