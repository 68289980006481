import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faHome, faPhone, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const UtilityDiv = styled.div`
  width: 33.3%;
  display: flex;
  flex-direction: column;
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const HalfContainer = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: 50%;
  }

  ${({ css }) => css && `${css}`}
`;

const FooterContainer = styled.div`
  background-color: #2b2b2b;
  position: absolute;
  width: 100%;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    padding: 1rem 20px;
  }
  ${({ productspath }) => productspath && `@media(max-width: 1024px){display: none !important}`}
`;

const links = [
  {
    title: 'Informações',
    links: [
      { text: 'FAQ - Perguntas Frequentes', path: '/perguntas-frequentes' },
      { text: 'Política de Privacidade', path: '/politica-privacidade' },
      { text: 'Termos e Condições', path: '/termos-condicoes' },
    ],
  },
  {
    title: 'Porquê a Alflora?',
    links: [
      { text: 'Quem Somos', path: '/empresa' },
      { text: 'Compromisso de Qualidade', path: '/...' },
    ],
  },
  {
    title: 'Ligações Úteis',
    links: [
      { text: 'Como adicionar à Encomenda', path: '/...' },
      { text: 'Como Registar', path: '/...' },
    ],
  },
];

const Footer: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <FooterContainer className="footer" productspath={pathname === '/produtos'}>
      <div className="flex flex-row flex-wrap">
        <HalfContainer className="quem-somos" css="@media(min-width: 1024px){padding-right: 8rem; padding-left: 4rem}">
          <h2>SOBRE NÓS</h2>
          <p>
            Somos uma empresa de Grossismo / Revenda do sector da Decoração & Arte Floral, com showroom temático e permanente de 3000m2, com
            mais de 25000 referências. Através destas características empresariais, somos um dos líderes no panorama nacional.
          </p>
          <p>
            <FontAwesomeIcon icon={faHome} /> R. Cidres 1263, 4455-442 Freixieiro-Perafita
          </p>
          <p>
            <FontAwesomeIcon icon={faMobileAlt} /> +351 913 463 809
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} /> +351 22 996 5820
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> info@alflora.com
          </p>
          <div className="sobre-nos py-2">
            <h2>SIGA-NOS</h2>
            <p>
              Acompanhe a Alflora nas redes sociais: <FontAwesomeIcon icon={faFacebookF} />
            </p>
          </div>
        </HalfContainer>
        <HalfContainer className="utilidades">
          <div className="flex flex-row flex-wrap">
            {links.map((item, idx) => (
              <UtilityDiv className="mx-2" key={idx}>
                <h2>{item.title}</h2>
                {item.links.map((link, idx) => (
                  <Link to={link.path} key={idx}>
                    {link.text}
                  </Link>
                ))}
              </UtilityDiv>
            ))}
          </div>
          <div className="text-center lg:mt-32 all-rights flex flex-col">
            <p>Alflora - Comércio de Flores, S.A. - Todos os direitos reservados 2017</p>
            <a href="www.magikevolution.com" target="_blank">
              POWERED BY: MAGIKEVOLUTION
            </a>
          </div>
        </HalfContainer>
      </div>
    </FooterContainer>
  );
};

export default Footer;
