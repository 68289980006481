import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Container from "../../pieces/small/Container";
import AboutUsButton from "../../pieces/small/AboutUsButton";
import AccountData from "./AccountData";
import useInput from "../../../hooks/useInput";
import OrderData from "./OrderData";
import getOrders from "../../../api/getOrders";
import { useCookies } from "react-cookie";
import useLoading from "../../../hooks/useLoading";
import LoadingSpinner from "../../pieces/small/LoadingSpinner";
import getAccount from "../../../api/getMyAccount";
import { User } from "../../../api/postUser";

const ButtonContainer = styled.div`
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
`;

const containerCss: string = `@media(max-width: 1024px){margin: 10px 20px;padding-top: 20px;}@media(max-width: 500px){margin: 10px;}`;

const Account: React.FC = () => {
  const [orders, setOrders] = useState();
  const [account, setAccount] = useState<User>(null);
  const [currentSection, setCurrentSection] = useState<string>("dados_encomendas");
  const [loading, loadTools] = useLoading(true);
  const [cookie] = useCookies();

  const toggleSection = (e) => {
    const newSection: string = e.target.id;
    setCurrentSection(newSection);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadTools.start();
    getOrders(cookie["alflora-key"], 1, (data) => {
      setOrders(data.orders);
      loadTools.loaded();
    });

    getAccount(cookie["alflora-key"], (account: User) => {
      if (account) setAccount(account);
    });
  }, []);

  return (
    <Container extraStyle={{ marginTop: "0" }} css={containerCss}>
      <ButtonContainer>
        <AboutUsButton size="w-1/2" id="dados_pessoais" current={currentSection} onClick={toggleSection}>
          Dados Pessoais
        </AboutUsButton>
        <AboutUsButton size="w-1/2" id="dados_encomendas" current={currentSection} onClick={toggleSection}>
          Dados Encomendas
        </AboutUsButton>
      </ButtonContainer>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <AccountData current={currentSection} account={account} />
          <OrderData current={currentSection} orders={orders} />
        </>
      )}
    </Container>
  );
};

export default React.memo(Account);
