import { useState } from 'react';

const pageDefault = { actualPage: 1, lastPage: 1 };

const usePagination = (defaultObject = pageDefault) => {
  const [actualPage, setActualPage] = useState<number>(defaultObject.actualPage);
  const [lastPage, setLastPage] = useState<number>(defaultObject.lastPage);
  const [pageInfo, setPageInfo] = useState(null);

  const selectPage = (e: React.ChangeEvent<HTMLButtonElement>) => {
    const val = Number(e.target.id);
    setLastPage(actualPage);
    setActualPage(val);
    //window.scrollTo(0,0)
  };

  const resetPage = () => {
    setLastPage(1);
    setActualPage(1);
  };

  const pageTools = {
    lastPage,
    actualPage,
    pageInfo,
    selectPage,
    resetPage,
    directSelect: (val: number, last: number) => {
      setActualPage(val);
      setLastPage(last);
    },
    getPageInfo: (data) => {
      const pageInf = {
        pages: data.pages,
        actualPage: data.actualPage,
        totalPages: data.totalPages,
      };
      setPageInfo(pageInf);
    },
    set: (obj) => {
      setActualPage(obj.actualPage);
      setLastPage(obj.lastPage);
    },
  };

  return { actualPage, pageTools };
};

export default usePagination;
