import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SidebarAnchor = styled(Link)`
  padding: 6px 10px;
  font-size: 18px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
`;

interface SidebarItemPropTypes {
  name: string;
  to: string;
  onClick?: any;
}

const SidebarItem: React.FC<SidebarItemPropTypes> = ({ name, to, onClick = () => {} }) => {
  return (
    <SidebarAnchor to={to} onClick={onClick}>
      {name}
    </SidebarAnchor>
  );
};

export default SidebarItem;
