import { useState } from "react";

export default function useNumberInput(initialValue: number, onChangeCb = (any) => {}): [number | React.ReactText, (e: React.ChangeEvent<HTMLInputElement>) => void, (e: React.ChangeEvent<HTMLInputElement>) => void, (num: number) => void] {
  const [quantity, setQuantity] = useState<number | React.ReactText>(initialValue);

  const operate = (e: React.ChangeEvent<HTMLButtonElement>) => {
    const operator: string = e.target.id;
    let newQuantity: number = Number(quantity);
    if (operator === "+") {
      newQuantity++;
      setQuantity(newQuantity);
      onChangeCb(newQuantity);
    } else if (operator === "-" && quantity > 1) {
      newQuantity--;
      setQuantity(newQuantity);
      onChangeCb(newQuantity);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 0) {
      setQuantity(e.target.value);
      onChangeCb(e.target.value);
    } else {
      setQuantity(1);
      onChangeCb(1);
    }
  };

  const set = (val) => {
    setQuantity(val);
  };

  return [quantity, operate, onChange, set];
}
