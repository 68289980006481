import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";

const Button = styled.button`
  border-radius: 6px;
  background-color: ${process.env.REACT_APP_BGCOLOR};
  margin-left: auto;
  height: 100%;
  width: 100%;
  transition: 0.4s linear;
  border: 1px solid ${process.env.REACT_APP_BGCOLOR};
  color: #f1f1f1;
  &:focus {
    outline: none;
  }
`;

type BuyButtonProps = {
  onClick: (e: React.FormEvent<HTMLButtonElement>) => void;
};

const BuyButton: React.FC<BuyButtonProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <FontAwesomeIcon icon={faShoppingBasket} />
    </Button>
  );
};

export default BuyButton;
