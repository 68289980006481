import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';


const getProducts = async (key: string, actualPage: number | 2,currentSort, filters, cb1 = (data) => {}, cb2 = (err) => {}) => {
  const url: string | undefined = process.env.REACT_APP_URL;
  let fullUrl: string = `${url}/products/filter`;

  const params = {
    page: actualPage,
    active: String(true),
    //limit: 45,
    sort: currentSort,
    limit: 21,
    sku: '%',
    stock: '>0',
    ...filters,
  };


  let config: any = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${key}`,
    },
    params
  };

  try {
    const res: AxiosResponse = await axios.get(fullUrl, config);
    //res.data.product.forEach(product => console.log(product.stock, product.active))
    cb1(res.data);
  } catch (err) {
    cb2(err);
  }
};

export default getProducts;
