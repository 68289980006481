import axios, { AxiosResponse } from "axios";

export default async function createOrder(key, payload, onSucess, onError) {
  const baseUrl = process.env.REACT_APP_URL;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${key}`,
    },
  };

  try {
    const res: AxiosResponse = await axios.post(
      `${baseUrl}/orders`,
      payload,
      config
    );
    onSucess(res.data);
  } catch (err) {
    onError(err);
  }
}
