import React from 'react';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
//import { InputWrapper, Input, ArrowWrapper, ArrowButton, Icon } from "./styled";
import styled from 'styled-components';

const Input = styled.input`
  background-color: white;
  width: 50px;
  text-align: center;
  margin: 0 5px;
  border-radius: 3px;
  border: 2px solid #333333b3;
  &:focus {
    outline: none;
  }
  ${({ size }) => size === 'large' && `width: 60px;`}
`;
const Button = styled.button`
  padding: 10px 15px;
  background: #f1f1f1;
  border-radius: 3px;
  color: #333;
  font-size: 15px;
  padding: 10px 20px;
  &:focus {
    outline: none;
  }
  ${({ size }) => size === 'large' && `padding: 10px 20px;`}
`;

const InputWrapper = styled.div`
  ${({ css }) => css && css}
`;

type NumberInputProps = {
  value: any;
  onChange: any;
  operator: any;
  isProductDetail?: boolean;
  className ?: string;
  css?: string;
};

const NumberInput: React.FC<NumberInputProps> = ({ value, onChange, operator, isProductDetail = 'normal', css = '', className = '' }) => {
  return (
    <InputWrapper className={`flex flex-row ${isProductDetail && 'mr-3'} ${className}`} css={css}>
      <Button side="down" onClick={operator} id="-" size={isProductDetail && 'large'}>
        -
      </Button>
      <Input type="number" value={value} onChange={onChange} size={isProductDetail && 'large'} />
      <Button side="up" onClick={operator} id="+" size={isProductDetail && 'large'}>
        +
      </Button>
    </InputWrapper>
  );
};

export default React.memo(NumberInput);

/*return (
    <InputWrapper>
      <Input type="number" value={value} onChange={onChange} />
      <ArrowWrapper>
        <ArrowButton
          side="up"
          onClick={operator}
          id="+"
          isproductdetail={isProductDetail}
        >
          <Icon icon={faSortUp} isproductdetail={isProductDetail} />
        </ArrowButton>
        <ArrowButton
          side="down"
          onClick={operator}
          id="-"
          isproductdetail={isProductDetail}
        >
          <Icon
            icon={faSortDown}
            side="down"
            isproductdetail={isProductDetail}
          />
        </ArrowButton>
      </ArrowWrapper>
    </InputWrapper>
  );*/
