import styled from 'styled-components';

const CartTitle = styled.p`
  margin-top: 0;
  font-weight: 700;
  margin-bottom: 15px;
  font-family: arial;
  font-size: 16px;
`;

const CartButton = styled.button`
  width: 100%;
  padding: 8px 10px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-weight: 500;
  color: white;
  transition: 0.2s linear;
  border-radius: 3px;
  background-color: ${process.env.REACT_APP_2COLOR};
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #264c2c;
  }
`;

const CartWrapper = styled.div`
  position: absolute;
  background: white;
  padding: 15px 18px;
  left: -250px;
  top: 95px;
  max-height: 380.61px;
  overflow-y: auto;
  border: 1px solid #ccc;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 340px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  ${({ mobile }) => mobile && `left: -320px;top: 35px;`}
`;

const QuantifySpan = styled.span`
  color: ${process.env.REACT_APP_2COLOR};
  font-weight: 700;
`;

export { QuantifySpan, CartWrapper, CartButton, CartTitle };
