import { useState } from "react";

export default function useLoading(initialVal) {
  const [loading, setLoading] = useState(initialVal);

  const loadTools = {
    loaded: () => {
      setLoading(false);
    },
    start: () => {
      setLoading(true);
    },
  };

  return [loading, loadTools];
}
